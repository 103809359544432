/** Redux */
import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';

/** Reducers */
import userDatasReducer from './reducers/userDatas';
import loadersReducer from './reducers/loaders';
import togglerReducer from './reducers/togglers';

const store = configureStore({
	reducer: combineReducers({
		userDatas: userDatasReducer,
		loaders: loadersReducer,
		togglers:  togglerReducer
	})
});

export default store;