/** Dependencies */
import { useEffect, useState } from 'react';

/** Components */
import FilterSliderDropDownMenu from './../../Filters/FilterSliderDropDownMenu';
import DropDownMenu from './../../Filters/DropDownMenu';

/** Helpers */
import { deepEqual } from './../../../helpers/functions';

/** SCSS */
import './FiltersWindowWidgets.scss';
import './Range.scss';

function Range( props )
{
  const {
    id,
    pictoTitle,
    filterLabel,
    initFilterValues,
    callBackFunction,
    sliderMinValue,
    sliderMaxValue,
    sliderInterval,
    dropDownMenuRangeValues,
    dropDownMenuEvolValues,
    dropDownMenuMoveValues    
  } = props;

  /** Init State */
  const [ filterValues, setFilterValues ] = useState( initFilterValues !== null ? initFilterValues : null );

  /** Update Range filter values */
  useEffect( () => 
  {
    // callback function if filter values not null and different of init filter values
    if(
      filterValues !== null 
      && typeof callBackFunction === 'function' 
      && !deepEqual( filterValues, initFilterValues )
    )
      callBackFunction( filterValues );
    
  }, [ filterValues ] )

  return(
    <div className='filter-container range'>

      {/* Title */}
      <h4 className='picto-title'>
        { pictoTitle }
        { filterLabel }
      </h4>

      {/* Slider and predefined DropDown menu */}
      { 
        dropDownMenuRangeValues !== undefined 
        && Array.isArray( dropDownMenuRangeValues ) ?
          <FilterSliderDropDownMenu 
            id={ id + '-slider-dropdown-menu' }
            sliderMinValue={ sliderMinValue }
            sliderMaxValue={ sliderMaxValue }
            sliderInterval={ sliderInterval }
            dropDownMenuValues={ dropDownMenuRangeValues }
            initCurrentValues={ filterValues !== null && filterValues.value ? filterValues.value : null }
            callBackFunction={ values => setFilterValues( {...filterValues, value: values } ) }
          />
          : null
      }

      {/* Evolution DropDown menu */}
      { 
        dropDownMenuEvolValues !== undefined 
        && Array.isArray( dropDownMenuEvolValues ) ?
          <DropDownMenu
            id={ id + '-dropdown-menu-evol' }
            label="Evolution"
            dropDownMenuValues={ dropDownMenuEvolValues }
            initCurrentValues={ filterValues !== null && filterValues.variation ? filterValues.variation : null }
            callBackFunction={ values => setFilterValues( {...filterValues, variation: values } ) }
          />
          : null
      }

      {/* Mouvement DropDown menu */}
      { 
        dropDownMenuMoveValues !== undefined 
        && Array.isArray( dropDownMenuMoveValues ) ?
          <DropDownMenu 
            id={ id + '-dropdown-menu-move' }
            label="Mouvement"
            dropDownMenuValues={ dropDownMenuMoveValues } 
            initCurrentValues={ filterValues !== null && filterValues.movement ? filterValues.movement : null }
            callBackFunction={ values => setFilterValues( {...filterValues, movement: values } ) }
          />
          : null
      }

    </div>
  )
}

export default Range;