/** Dependencies */
import { Chart } from 'smart-webcomponents-react/chart';

/** Helpers */
import { getMinMaxFromArrayObject } from './../../helpers/functions';

/** SCSS */
import './InsightsCompareLineChart.scss';

function InsightsCompareLineChart( props )
{
  const {
    id,
    datas,
    dataField,
    flip,
    xAxisPosition,
    xAxisLabelVisible
  } = props;
  
  // Define positive and negitive colors
  const gridLinesColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-grey3' ).trim();
  const compareSerieColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-grey2' ).trim();
  const positiveColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-orange-opacity' ).trim();
  const negativeColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-blue-opacity' ).trim();

  // get min / max values from compare and current datas
  const minMaxValues = getMinMaxFromArrayObject( datas, ['current', 'compare'] );

  // Define settings for chart
  const settings = {
    caption: '',
    description: '',
    showLegend: false,
    animation: 'none',
    showBorderLine: false,
    showToolTips: true,
    backgroundColor: 'white',
    columnSeriesOverlap: true,
    toolTipFormatFunction: ( value, itemIndex, serie, group, xAxisValue, xAxis ) => xAxisValue + ' : ' +  Intl.NumberFormat('fr-FR').format( value ),
    enableCrosshairs: true,
    padding: {
      left: 0,
      top: 5,
      right: 0,
      bottom: 5
    },
    titlePadding: {
      left: 0,
      top: 0,
      right: 0,
      bottom: 0
    },
    dataSource: datas,
    xAxis: {
      dataField: dataField,
      position: xAxisPosition !== undefined ? xAxisPosition : 'bottom',
      tickMarks: {
        visible: false
      },
      labels: {
        visible: xAxisLabelVisible !== undefined ? xAxisLabelVisible : true,
        class: 'x-axis-labels',        
        offset: {
          y: flip?.valueAxis !== undefined ? -5 : 5
        }
      },
      gridLines: {
        color: gridLinesColor
      },
      valuesOnTicks: false
    },
    seriesGroups: [      
      {
        type: 'line',
        valueAxis: {
          axisSize: 30,
          visible: true,
          minValue: 0,
          maxValue: minMaxValues?.global?.max !== undefined ? minMaxValues?.global?.max + 2 : 100,
          flip: flip?.valueAxis !== undefined ? flip.valueAxis : false,
          gridLines: {
            dashStyle: '4,4',
            color: gridLinesColor
          },
          tickMarks: {
            visible: false
          },
          labels: {
            class: 'values-axis-labels'
          }
        },
        series:[
          { 
            dataField: 'compare',
            fillColor: compareSerieColor,
            lineColor: compareSerieColor,
            lineWidth: 2,
            opacity: 0.25
          }           
        ]
      },
      {
        type: 'line',
        valueAxis: {
          visible: false,
          minValue: 0,
          maxValue: minMaxValues?.global?.max !== undefined ? minMaxValues?.global?.max + 2 : 100,
          flip: flip?.valueAxis !== undefined ? flip.valueAxis : false
        },
        series:[
          { 
            dataField: 'current',
            symbolType: 'circle',
            symbolSize: 10,
            lineWidth: 2,
            colorFunction: ( value, itemIndex ) => 
            {
              // set color
              let fillColor = positiveColor;
              if( datas.length > 1 )
              {
                if( itemIndex === 0 && value < datas[1].current )
                  fillColor = positiveColor;
                else if( itemIndex === 0 && value > datas[1].current )
                  fillColor = negativeColor;
                else if ( itemIndex > 0 && datas[itemIndex - 1] && value < datas[itemIndex - 1].current )
                  fillColor = positiveColor;
                else if ( itemIndex > 0 && datas[itemIndex - 1] && value > datas[itemIndex - 1].current )
                  fillColor = negativeColor;
              }

              // reverse color if chart is fliped
              if( flip?.valueAxis === true && fillColor === negativeColor )
                fillColor = positiveColor;
              else if( flip?.valueAxis === true && fillColor === positiveColor )
                fillColor = negativeColor;

              return {
                fillColor: fillColor,
                fillColorSymbolSelected: 'white'
              };
            }  
          }
        ]
      }      
    ]
  };
  
  return(
    <div className="insights-line-chart">
      <Chart
				id = { id + '-chart' }
        {...settings}
			/>
    </div>
  )
}

export default InsightsCompareLineChart;