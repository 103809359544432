/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'smart-webcomponents-react/source/modules/smart.form';
import { Button } from 'smart-webcomponents-react/button';

/** Components */
import Loader from './../../components/Loader/Loader';

/** Reducers */
import { updateUserDatas } from './../../reducers/userDatas';
import { onToggler, offToggler } from './../../reducers/togglers';

/** Services */
import { getItem } from "./../../services/LocaleStorage";
import { 
  logout, 
  login 
} from './../../services/AuthApi';

/** Class */
import wsLogin from './../../helpers/webservice/wsLogin.class.js';

/** Helpers */
import { getPicto } from './../../helpers/pictos';

/** Images */
import EmpirikLogo from './../../assets/img/Logos-Empirik-blanc-vertical.png';

/** SCSS */
import './LoginInfos.scss';

function LoginInfos( props )
{
  const {
    className
  } = props;

  /** Instance dispatch object **/
	const dispatch = useDispatch();

  /** Get state from redux store **/
  const togglers = useSelector( state => state.togglers.value );
	const userDatas = useSelector( state => state.userDatas.value );

  /** Init State */
  const [form, setForm] = useState( null );
  const [isFormValid, setIsFormValid] = useState( false );
  const [message, setMessage] = useState( '' );

  /** Define colors */
  const loaderColor = getComputedStyle( document.documentElement ).getPropertyValue('--color-blue').trim();

  /** Instance wsLogin Class */
  const wsLoginClass = new wsLogin(
    'authentication',
    dispatch
  );

  /** Init has authentication */
  const initAuthenticate = ( results, message ) => 
  {
    // loing or logout with response
    if( results.response === true ){
      setMessage( '' );
      handleLogin( results );
    } else {
      setMessage( message );
      handleLogout();
    }
  }

  /** Handle login actions */
  const handleLogin = datas => 
  {
    login( datas );
    dispatch( onToggler( 'isAuthenticated' ) )
    dispatch( updateUserDatas( datas.data ) );
  }

  /** Handle logout actions */
  const handleLogout = () => 
  {
    logout();
    dispatch( offToggler( 'isAuthenticated' ) )
    dispatch( updateUserDatas( [] ) );
  }

  /** Init form */
  const initForm = () => 
  {
    // init form
    setForm( 
      new window.Smart.Form( '#authenticateForm', {
        value: {
          user: {
            email: '',
            password: ''
          }
        },
        controls: [{
          controlType: 'group',
          columns: 2,
          dataField: 'user',
          labelPosition: 'top',
          controls: [        
            {
              dataField: 'email',
              controlType: 'input',
              validationRules: [{
                type: 'email', 
                message: 'Merci de saisir un email valide.'
              }],
              required: true,
              placeholder: 'Email',
              cssClass: 'underlined'
            },
            {
              dataField: 'password',
              controlType: 'password',
              validationRules: [{ 
                type: 'required', 
                message: 'Mot de passe requis.' 
              }],
              required: true,
              placeholder: 'Mot de passe',            
              cssClass: 'underlined'
            }
          ]
        }]
      })
    );   
  }

  /** Init if form is valid */
  useEffect( () => 
  {
    // set form valid state when form is valid
    if( form !== null )      
      form.onStatusChanges = ( value ) => setIsFormValid( value.form === 'valid' ? true : false );

  }, [ form ]);

  /** Handle action after submit form */
  const handlerSubmitForm = () => 
  {
    wsLoginClass.checkLogin({
      email: form.value.user.email,
      password: form.value.user.password
    },{ 
      function: 'initAuthenticate', 
      params: [ "Votre email ou mot de passe n'est pas valide." ] 
    });
  }

  /** Set form if authentication is false */
  useEffect( () => 
  {
    if( togglers?.isAuthenticated !== undefined && togglers.isAuthenticated === true )
      setForm( null );
    else if( togglers?.isAuthenticated !== undefined && togglers.isAuthenticated === false )
      initForm();

  }, [ togglers ] )

  /** Init check login */
  useEffect( () => 
  {
    if(
      getItem( 'token' ) !== null 
      && getItem( 'email' ) !== null
    )
      wsLoginClass.checkLogin({
        email: getItem( 'email' ),
        token: getItem( 'token' )
      },{ 
        function: 'initAuthenticate' 
      });
    else
      handleLogout();

  }, [] );

  return(
    <div className={ className ? 'login-infos ' + className : 'login-infos' }>
      <Loader 
        loaderID="authentication" 
        loaderStyle={{
          width:'25', 
          stroke: loaderColor, 
          viewBox:'-2 -2 42 42'
        }}
        callBackFcts={{
          initAuthenticate: ( results, params ) => initAuthenticate( results, params )
        }}
      />
      <div className={ message !== '' ? 'message' : 'message hide' }>
        <p>{ message }</p>
      </div>
      { togglers?.isAuthenticated !== undefined && togglers.isAuthenticated === true ?
          <React.Fragment>
            <img src={ userDatas.picture } alt={ userDatas.first_name } title={ userDatas.first_name } className="picture" />
            <img src={ EmpirikLogo } alt="Empirik - Impakkt" title="Empirik - Impakkt" height={ 100 } className='logo' />
          </React.Fragment>
        : togglers?.isAuthenticated !== undefined && togglers.isAuthenticated === false ?
          <React.Fragment>
            <form id="authenticateForm"></form>
            <Button 
              className="flat circle"
              onClick={ () => handlerSubmitForm() } 
              disabled={ !isFormValid }
            >
              { getPicto( 'SignIn', { size: '1.4rem', weight: "bold" } ) }
            </Button>
          </React.Fragment>
        : null
      }      
    </div>
  )
}

export default LoginInfos;