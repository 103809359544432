/** Dependencies */
import { useSelector, useDispatch } from 'react-redux';

/** Reducers */
import { onToggler } from './../../reducers/togglers';

/** Helpers */
import { getPicto } from "./../../helpers/pictos";

function FilterRemoverRange( props )
{
  const{ 
    label,
    field,
    value,
    filterName,
    callBackFunction
  } = props;
  
  /** Instance dispatch object **/
	const dispatch = useDispatch();
  
  /** Get state from redux store **/
  const togglers = useSelector( state => state.togglers.value );

  // Define increase and decrease colors
  const grey2Color = getComputedStyle( document.documentElement ).getPropertyValue('--color-grey2').trim();

  return(
    <div 
      className="filter range"
      id={ 'filter-' + filterName + '-' + field }
    >
      <div 
        className="label" 
        title={ label + ' : ' + value.map( value => Intl.NumberFormat( 'fr-FR', { notation: 'compact' } ).format( value ) ).join( '-' ) }
        onClick={ !togglers[ 'filters-windowbutton' ] ? () => dispatch( onToggler( 'filters-windowbutton' ) ) : null }
      >
        { label }
        &nbsp;
        { 
          filterName === 'position' && value[0] === 100 && value[1] === 101 ? 
            'NP':
            value.map( value => Intl.NumberFormat( 'fr-FR', { notation: 'compact' } ).format( value ) ).join( '-' ) 
        }
      </div>
      <button
        onClick={ () => callBackFunction( filterName, field ) }
      >{ getPicto( 'X', { size: '1rem', fill: grey2Color, weight: 'bold' } ) }</button>
    </div>
  )
}

export default FilterRemoverRange;