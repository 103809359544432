/** Dependencies */
import { Fragment, useState, useEffect, useRef } from 'react';
import { useSearchParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Button } from 'smart-webcomponents-react/button';
import { ButtonGroup } from 'smart-webcomponents-react/buttongroup';
import { useNavigate, useParams } from "react-router-dom";

/** Components */
import CompareValuesChart from './../../components/Widgets/DashboardWidgets/CompareValuesChart';
import InstanceInfos from './../../components/Instances/InstanceInfos';
import PeriodSelector from './../../components/PeriodSelector/PeriodSelector';
import PeriodCalendar from './../../components/PeriodCalendar/PeriodCalendar';
import RangeGranularity from './../../components/RangeGranularity/RangeGranularity';
import GridCoreDashboard from './../../components/Grid/Core/Dashboard/GridCoreDashboard';
import FiltersWindow from './../../components/FiltersWindow/FiltersWindow';
import KeywordsGroupsWindow from './../../components/KeywordsGroupsWindow/KeywordsGroupsWindow';
import WindowButton from './../../components/WindowButton/WindowButton';
import DisplayModeSelector from './../../components/DisplayModeSelector/DisplayModeSelector';
import FiltersRemovers from './../../components/FiltersRemovers/FiltersRemovers';

/** Helpers */
import { getPicto } from './../../helpers/pictos';
import { getInstanceDatas } from './../../helpers/instance';
import { filterTopXFromArrayObject, sortArrayByObjectField } from './../../helpers/functions';
import { 
  aggregateDatasForInsightCompareSum, 
  aggregateDatasForInsightCompareMostRecent,
  aggregateDatasForInsightGrpSnippetsList,
  aggregateDatasForInsightTypeSnippetsListMostRecent,
  createCustomSeriesOrderByValues  
} from './../../helpers/datas';

/** SCSS */
import '@phosphor-icons/web/regular';
import './InstanceDashboard.scss';

function InstanceDashboard()
{
  /** Instance useNavigate */
  const navigate = useNavigate();

  /** Init State */
  const [ mcpPositionChartType, setMcpPositionChartType ] = useState( 'line' );
  const [ serpCompositionChartType, setSerpCompositionChartType ] = useState( 'grouped' );
  const [ serpRateChartType, setSerpRateChartType ] = useState( 'grouped' );
  const [ serpHitsChartType, setSerpHitsChartType ] = useState( 'grouped' );
  const [ gridParams, setGridParams ] = useState( null );
  const [ gridKeywords, setGridKeywords ] = useState( 'followedKeywords' );
  const [ gridSelectedKeywords, setGridSelectedKeywords ] = useState( [] );
  const [ gridSelectedKeywordsGroups, setGridSelectedKeywordsGroups ] = useState( [] );
  const [ chartSelectedKeywords, setChartSelectedKeywords ] = useState( [] );
  const [ gridLabels, setGridLabels ] = useState( [] );
  const [ gridUrls, setGridUrls ] = useState( [] );
  const [ currentPeriod, setCurrentPeriod ] = useState( null );
  const [ comparePeriod, setComparePeriod ] = useState( null );
  const [ minMaxValues, setMinMaxValues ] = useState( null );
  const [ displayMode, setDisplayMode ] = useState( 'chart-grid' );
  const [ currentFilters, setCurrentFilters ] = useState( null );
  const [ box2Width, setBox2Width ] = useState( null );

  /** Define Refs */
  const box2Ref = useRef( null );

  /** Get state from redux store **/
  const userDatas = useSelector( state => state.userDatas.value );

  /** UseSearchParams Hook */ 
  const [ searchParams ] = useSearchParams();

  // Define increase and decrease colors
  const grey2Color = getComputedStyle( document.documentElement ).getPropertyValue('--color-grey2').trim();
  const yellowColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-yellow' ).trim();
  const blueColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-blue' ).trim();
  const orangeColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-orange' ).trim();

  // get instance infos from url
  const { clientID } = useParams();

  useEffect( () =>
  {
    if( gridParams?.keywords !== undefined ) setGridKeywords( gridParams.keywords );
    if( gridParams?.selectedKeywords !== undefined ) setGridSelectedKeywords( gridParams.selectedKeywords );
    if( gridParams?.labels !== undefined ) setGridLabels( gridParams.labels );
    if( gridParams?.urls !== undefined ) setGridUrls( gridParams.urls );
    if( gridParams?.currentPeriod !== undefined ) setCurrentPeriod( gridParams.currentPeriod );
    if( gridParams?.comparePeriod !== undefined ) setComparePeriod( gridParams.comparePeriod );
    if( gridParams?.minMaxValues !== undefined ) setMinMaxValues( gridParams.minMaxValues );
    
  }, [ gridParams ] );

  useEffect( () => 
  {
    if( clientID === undefined )
    {
      // get nickname of first instance after sort by label
      const firstNickname = sortArrayByObjectField( structuredClone( userDatas.enabled_instances ), 'label' )[0].nickname;

      // get first instance datas
      const firstInstanceDatas =  getInstanceDatas( firstNickname, userDatas );

      // set redirect url
      let url = '/dashboard/' + firstNickname;
      url += '/' + firstInstanceDatas.devLoc[0].countryValue;
      url += '/' + firstInstanceDatas.devLoc[0].deviceValue;

      // redirect to url
      navigate( url );
    }
    
  }, [] );

  useEffect( () => 
  {
    if( searchParams.get( 'filters' ) !== null )
      setCurrentFilters( JSON.parse( searchParams.get( 'filters' ) ) );
    else
      setCurrentFilters( [] );

  }, [ searchParams.get( 'filters' ) ]);

  /** Add event listener on resize screen */
  useEffect( () => 
  {
    if( box2Ref?.current !== null && box2Ref?.current !== undefined )
    {
      const updateFiltersWindowWidth = () => setBox2Width( box2Ref.current.offsetWidth );

      // init width
      updateFiltersWindowWidth();

      // add event listener on screen resize
      window.addEventListener( 'resize', updateFiltersWindowWidth );
      return () => {
        window.removeEventListener( 'resize', updateFiltersWindowWidth );
      };
    }

  }, [ box2Ref.current ]);

  return(
    <div className = { displayMode !== 'chart-grid' ? 'instance-dashboard ' + displayMode : 'instance-dashboard' }>
      { clientID !== undefined ?
        <Fragment>

          <div className='box1'>
            <InstanceInfos />

            <div className='period-infos-wrapper'>
              <PeriodSelector />
              <PeriodCalendar />
            </div>
          </div>

          <div className='box2' ref={ box2Ref }>
            <div className='header'>            
              <div className='header-left-container'>
                <h3>Marché</h3>
                  <DisplayModeSelector
                    callBackFunction = { setDisplayMode }
                    currentValue = { displayMode }
                  /> 
              </div>
              <div className='header-right-container'>

                {/* Filters removers */}
                <FiltersRemovers />
                
                {/* Filters / Keywords groups => Button */}

                {/* Filters button */}
                <WindowButton 
                  id="filters"
                  className="filters"
                  activeEffect="horizontalFlip"
                  buttonContent={ getPicto( 'Funnel', { size: '1.5rem', fill: grey2Color } ) }
                  windowContent={ 
                    <FiltersWindow 
                      minMaxValues={ minMaxValues } 
                      autoCompletedLabelsValues={ gridLabels } 
                      autoCompletedUrlsValues={ gridUrls }                             
                    /> 
                  }
                  indicator={ currentFilters !== null ? 
                    currentFilters.map( filter => Object.keys( filter.values ).length ).reduce( ( a, v ) => a + v, 0 ) 
                    : null 
                  }
                  width={ box2Width }
                />

                {/* Keywords groups button  */}
                <WindowButton 
                  id="keywords-groups"
                  className="keywords-groups"
                  activeEffect="horizontalFlip"
                  buttonContent={ getPicto( 'Bookmarks', { size: '1.5rem', fill: grey2Color } ) }
                  windowContent={ 
                    <KeywordsGroupsWindow 
                      currentSelectedKeywords={ gridSelectedKeywords }
                      callBackFunction={ values => setGridSelectedKeywordsGroups( values ) }
                    />
                  }                        
                />
                
                {/* <Button className='flat circle'>{ getPicto( 'FloppyDisk', { size: '1.2rem' } ) }</Button>              
                <Button className='flat circle'>{ getPicto( 'DotsThreeVertical', { size: '1.6rem', weight: 'bold' } ) }</Button> */}
                
              </div>
            </div>

            {/* Next page button */}
            <Button 
              className='flat fill next-page'
              disabled={ gridSelectedKeywords.length === 0 ? true : false }
            >
              { getPicto( 'Control', { size: '1.5rem', transform: 'rotate( 90 )' } ) }
              <p className='checkboxes-sum'>{ gridSelectedKeywords.length }</p>
            </Button>

          </div>
          
          <div className='box3'>   
            
            {/* box3 header */}
            <div className='header'>
              <div className='left-col'>&nbsp;</div>
              <RangeGranularity />
              <div className='right-col'>
                <Button 
                  disabled={ 
                    ( 
                      gridSelectedKeywords.length === chartSelectedKeywords.length 
                      && gridSelectedKeywords.filter( elem => !chartSelectedKeywords.includes( elem ) ).length === 0 ? 
                        true : false
                    )
                  }
                  className='flat circle'
                  onClick={ () => gridSelectedKeywords.length > 0 ? setChartSelectedKeywords( gridSelectedKeywords ) : setChartSelectedKeywords( [] ) }
                >{ getPicto( 'ArrowsClockwise', { size: '1.6rem', color: grey2Color } ) }</Button>                  
              </div>           
            </div>

            {/* Highlight granularity behind graphs */}
            <div className='widget-wrapper highlight-granularity'>
              <RangeGranularity hightLightOnly={ true } />
            </div>          
            
            {/* Clicks */}
            <div className='widget-wrapper'>
              <CompareValuesChart
                id="mcp-clicks"
                title="Nombre de clics"
                pictoTitle={ getPicto( 'Mouse', { size: "1rem", weight:'bold', color: grey2Color } ) }
                typeChart="column"
                serviceName="gsc-trend"
                params={{
                  currentPeriod: currentPeriod,
                  comparePeriod: comparePeriod,
                  webservice: { 
                    where: { 
                      keywords: chartSelectedKeywords.length > 0 ? chartSelectedKeywords : gridKeywords
                    }
                  }
                }}
                formatResultFunction={ results => results.clicks }
                aggregateDatasForInsight={ aggregateDatasForInsightCompareSum }
                xAxisLabelVisible={ false }
              />
            </div>

            {/* Average positions */}
            <div className='widget-wrapper'>
              { mcpPositionChartType === 'line' ?
                <CompareValuesChart
                  id="mcp-avg-position"
                  title="Position du dernier scrap"
                  pictoTitle={ getPicto( 'ArrowsVertical', { size: "1rem", weight:'bold', color: grey2Color } ) }
                  typeChart="line"
                  serviceName="serp-trend-avgpos"
                  params={{
                    currentPeriod: currentPeriod,
                    comparePeriod: comparePeriod,
                    webservice: { 
                      where: { 
                        keywords: chartSelectedKeywords.length > 0 ? chartSelectedKeywords : gridKeywords,
                        who: ['me']
                      }
                    }
                  }}
                  aggregateDatasForInsight={ aggregateDatasForInsightCompareMostRecent }
                  flip={{ valueAxis: true }}
                  xAxisLabelVisible={ false }
                />
                :
                <CompareValuesChart
                  id="mcp-top-position"
                  title="Répartition des positions"
                  pictoTitle={ getPicto( 'ArrowsVertical', { size: "1rem", weight:'bold', color: grey2Color } ) }
                  typeChart="stackedColumn"
                  serviceName="serp-trend-range-positions"
                  params={{
                    currentPeriod: currentPeriod,
                    comparePeriod: comparePeriod,
                    webservice: { 
                      where: { 
                        keywords: chartSelectedKeywords.length > 0 ? chartSelectedKeywords : gridKeywords,
                        who: ['me']
                      }
                    }
                  }}
                  series={[
                    { 
                      dataField: 'top21andmore',
                      fillColor: grey2Color,
                      lineColor: grey2Color,
                      lineWidth: 0,
                      opacity: 0.3
                    },{ 
                      dataField: 'top1120',
                      fillColor: grey2Color,
                      lineColor: grey2Color,
                      lineWidth: 0,
                      opacity: 0.6
                    },{ 
                      dataField: 'top410',
                      fillColor: grey2Color,
                      lineColor: grey2Color,
                      lineWidth: 0,
                    },{ 
                      dataField: 'top3',
                      fillColor: yellowColor,
                      lineColor: yellowColor,
                      lineWidth: 0,
                    }
                  ]}
                  formatResultFunction={ results => results.me }
                  caption={['Top 3', '4 à 10', '11 à 20', '21 et plus']}
                  xAxisLabelVisible={ false }
                />
              }
              <div className='button-group-graph-select'>
                <ButtonGroup
                  className='column empty'
                  dataSource={[
                    { 
                      label: '<i class="ph ph-chart-line" style="font-size: 1.5rem"></i>', 
                      value: "line"
                    },
                    { 
                      label: '<i class="ph ph-chart-bar" style="font-size: 1.5rem"></i>', 
                      value: "stack"
                    }
                  ]}                
                  onChange={ e => setMcpPositionChartType( e.detail.values[0] ) }
                />
              </div>
            </div>

            {/* Search volume */}
            <div className='widget-wrapper'>
              <CompareValuesChart
                id="search-volume"
                title="Volumes de recherches"
                pictoTitle={ getPicto( 'MagnifyingGlass', { size: "1rem", weight:'bold', color: grey2Color } ) }
                typeChart="column"
                serviceName="volume-trend"
                params={{
                  currentPeriod: currentPeriod,
                  comparePeriod: comparePeriod,
                  webservice: { 
                    where: { 
                      keywords: chartSelectedKeywords.length > 0 ? chartSelectedKeywords : gridKeywords
                    }
                  }
                }}
                aggregateDatasForInsight={ aggregateDatasForInsightCompareSum }
                transparent={ true }
                xAxisLabelVisible={ false }
              />
            </div>

            {/* SERP composition */}
            <div className='widget-wrapper'>
              { serpCompositionChartType === 'grouped' ?
                <CompareValuesChart
                  id="serp-composition-grpsnippets"
                  title="Composition de la SERP"
                  pictoTitle={ getPicto( 'SortAscending', { size: "1rem", weight:'bold', color: grey2Color } ) }
                  typeChart="stackedColumn"
                  serviceName="serp-trend-rate-by-grpsnippets"
                  params={{
                    currentPeriod: currentPeriod,
                    comparePeriod: comparePeriod,
                    webservice: { 
                      where: { 
                        keywords: chartSelectedKeywords.length > 0 ? chartSelectedKeywords : gridKeywords
                      }
                    }
                  }}
                  series={[
                    { 
                      dataField: 'Snippets',
                      fillColor: yellowColor,
                      lineColor: yellowColor,
                      lineWidth: 0,
                      opacity: 0.8
                    },{ 
                      dataField: 'Ads',
                      fillColor: orangeColor,
                      lineColor: orangeColor,
                      lineWidth: 0,
                      opacity: 0.8
                    },{ 
                      dataField: 'SEO',
                      fillColor: blueColor,
                      lineColor: blueColor,
                      lineWidth: 0,
                      opacity: 0.8
                    }
                  ]}
                  maxValue={ 100 }
                  unit="&nbsp;%"
                  xAxisLabelVisible={ false }
                  formatResultFunction={ results => results?.all !== undefined ? filterTopXFromArrayObject( results.all, 5 ) : {} }
                  aggregateDatasForInsight={ aggregateDatasForInsightGrpSnippetsList }
                />
                :
                <CompareValuesChart
                  id="serp-composition-typesnippets"
                  title="Composition de la SERP"
                  pictoTitle={ getPicto( 'SortAscending', { size: "1rem", weight:'bold', color: grey2Color } ) }
                  typeChart="area"
                  serviceName="serp-trend-rate-by-typesnippets"
                  params={{
                    currentPeriod: currentPeriod,
                    comparePeriod: comparePeriod,
                    webservice: { 
                      where: { 
                        keywords: chartSelectedKeywords.length > 0 ? chartSelectedKeywords : gridKeywords
                      }
                    }
                  }}
                  series={ createCustomSeriesOrderByValues }
                  unit='&nbsp;%'
                  xAxisLabelVisible={ false }
                  formatResultFunction={ results => results?.all !== undefined ? filterTopXFromArrayObject( results.all, 5 ) : {} }
                  aggregateDatasForInsight={ aggregateDatasForInsightTypeSnippetsListMostRecent }
                />
              }
              <div className='button-group-graph-select'>
                <ButtonGroup
                  className='column empty'
                  dataSource={[
                    { 
                      label: '<div class="grouped-button"></div>', 
                      value: "grouped"
                    },
                    { 
                      label: '<div class="split-button"></div>', 
                      value: "split"
                    }
                  ]}                
                  onChange={ e => setSerpCompositionChartType( e.detail.values[0] ) }
                />               
              </div>
            </div>

            {/* SERP rate */}
            <div className='widget-wrapper'>
              { serpRateChartType === 'grouped' ?
                <CompareValuesChart
                  id="serp-rate-grpsnippets"
                  title="Mon occupation (% d'aire)"
                  pictoTitle={ getPicto( 'Seal', { size: "1rem", weight:'bold', color: grey2Color } ) }
                  typeChart="stackedColumn"
                  serviceName="serp-trend-rate-by-grpsnippets"
                  params={{
                    currentPeriod: currentPeriod,
                    comparePeriod: comparePeriod,
                    webservice: { 
                      where: { 
                        keywords: chartSelectedKeywords.length > 0 ? chartSelectedKeywords : gridKeywords,
                        who: ['me']
                      }
                    }
                  }}
                  series={[
                    { 
                      dataField: 'Snippets',
                      fillColor: yellowColor,
                      lineColor: yellowColor,
                      lineWidth: 0,
                      opacity: 0.8
                    },{ 
                      dataField: 'Ads',
                      fillColor: orangeColor,
                      lineColor: orangeColor,
                      lineWidth: 0,
                      opacity: 0.8
                    },{ 
                      dataField: 'SEO',
                      fillColor: blueColor,
                      lineColor: blueColor,
                      lineWidth: 0,
                      opacity: 0.8
                    }
                  ]}
                  unit="&nbsp;%"
                  xAxisLabelVisible={ false }
                  aggregateDatasForInsight={ aggregateDatasForInsightGrpSnippetsList }
                />    
                :
                <CompareValuesChart
                  id="serp-rate-typesnippets"
                  title="Mon occupation (% d'aire)"
                  pictoTitle={ getPicto( 'Seal', { size: "1rem", weight:'bold', color: grey2Color } ) }
                  typeChart="area"
                  serviceName="serp-trend-rate-by-typesnippets"
                  params={{
                    currentPeriod: currentPeriod,
                    comparePeriod: comparePeriod,
                    webservice: { 
                      where: { 
                        keywords: chartSelectedKeywords.length > 0 ? chartSelectedKeywords : gridKeywords,
                        who: ['me']
                      }
                    }
                  }}
                  series={ createCustomSeriesOrderByValues }
                  unit='&nbsp;%'
                  xAxisLabelVisible={ false }
                  formatResultFunction={ results => filterTopXFromArrayObject( results, 5 ) }
                  aggregateDatasForInsight={ aggregateDatasForInsightTypeSnippetsListMostRecent }
                />                            
              }
              <div className='button-group-graph-select'>
                <ButtonGroup
                  className='column empty'
                  dataSource={[
                    { 
                      label: '<div class="grouped-button"></div>', 
                      value: "grouped"
                    },
                    { 
                      label: '<div class="split-button"></div>', 
                      value: "split"
                    }
                  ]}                
                  onChange={ e => setSerpRateChartType( e.detail.values[0] ) }
                />               
              </div>
            </div>

            {/* SERP hits */}
            <div className='widget-wrapper'>
              { serpHitsChartType === 'grouped' ?
                <CompareValuesChart
                  id="serp-hits-grpsnippets"
                  title="Mon occupation (nb apparitions)"
                  pictoTitle={ getPicto( 'Lightbulb', { size: "1rem", weight:'bold', color: grey2Color } ) }
                  typeChart="stackedColumn"
                  serviceName="serp-trend-hits-by-grpsnippets"
                  params={{
                    currentPeriod: currentPeriod,
                    comparePeriod: comparePeriod,
                    webservice: { 
                      where: { 
                        keywords: chartSelectedKeywords.length > 0 ? chartSelectedKeywords : gridKeywords,
                        who: ['me']
                      }
                    }
                  }}
                  series={[
                    { 
                      dataField: 'Snippets',
                      fillColor: yellowColor,
                      lineColor: yellowColor,
                      lineWidth: 0,
                      opacity: 0.8
                    },{ 
                      dataField: 'Ads',
                      fillColor: orangeColor,
                      lineColor: orangeColor,
                      lineWidth: 0,
                      opacity: 0.8
                    },{ 
                      dataField: 'SEO',
                      fillColor: blueColor,
                      lineColor: blueColor,
                      lineWidth: 0,
                      opacity: 0.8
                    }
                  ]}
                  xAxisLabelVisible={ false }
                  aggregateDatasForInsight={ aggregateDatasForInsightGrpSnippetsList }
                />  
                :
                <CompareValuesChart
                  id="serp-hits-typesnippets"
                  title="Mon occupation (nb apparitions)"
                  pictoTitle={ getPicto( 'Lightbulb', { size: "1rem", weight:'bold', color: grey2Color } ) }
                  typeChart="stackedColumn"
                  serviceName="serp-trend-hits-by-typesnippets"
                  params={{
                    currentPeriod: currentPeriod,
                    comparePeriod: comparePeriod,
                    webservice: { 
                      where: { 
                        keywords: chartSelectedKeywords.length > 0 ? chartSelectedKeywords : gridKeywords,
                        who: ['me']
                      }
                    }
                  }}
                  series={ createCustomSeriesOrderByValues }
                  xAxisLabelVisible={ false }
                  formatResultFunction={ results => filterTopXFromArrayObject( results, 5 ) }
                  aggregateDatasForInsight={ aggregateDatasForInsightTypeSnippetsListMostRecent }
                />                
              }
              <div className='button-group-graph-select'>
                <ButtonGroup
                  className='column empty'
                  dataSource={[
                    { 
                      label: '<div class="grouped-button"></div>', 
                      value: "grouped"
                    },
                    { 
                      label: '<div class="split-button"></div>', 
                      value: "split"
                    }
                  ]}                
                  onChange={ e => setSerpHitsChartType( e.detail.values[0] ) }
                />
              </div>
            </div>

          </div>

          <div className='box4'> 
            <GridCoreDashboard 
              displayMode={ displayMode }
              gridSelectedKeywordsGroups={ gridSelectedKeywordsGroups }
              callBackFunction={ setGridParams } 
            />
          </div>
        </Fragment>
        : null 
      }
    </div>
  )  
}

export default InstanceDashboard;