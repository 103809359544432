import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {}
};

export const loadersSlice = createSlice({
  name: 'loaders',
  initialState,
  reducers: {
    addLoader: ( state, action ) => 
    {
      // add value into new state
			if( state.value[action.payload.loaderID] !== undefined )
        state.value[action.payload.loaderID].push( action.payload.request );				
      else
        state.value[action.payload.loaderID] = [action.payload.request];
    },
    removeLoader: ( state, action ) => 
    {
      // remove value from new state
      if( 
        state.value[action.payload.loaderID] !== undefined
        && state.value[action.payload.loaderID] instanceof Array 
      ){
        state.value[action.payload.loaderID] = state.value[action.payload.loaderID].filter( 
          loader => loader.id !== action.payload.request 
        );

        // remove key if empty
        if( state.value[action.payload.loaderID].length === 0 )
          delete state.value[action.payload.loaderID];
      }
    }
  }
})

export const { addLoader, removeLoader } = loadersSlice.actions

export default loadersSlice.reducer