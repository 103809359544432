/** Dependencies */
import { useEffect, useState } from 'react';

/** Components */
import DropDownMenuNested from './../../Filters/DropDownMenuNested';

/** SCSS */
import './FiltersWindowWidgets.scss';

function Categories( props )
{
  const {
    id,
    pictoTitle,
    filterLabel,
		categories,
    initFilterValues,
    callBackFunction
  } = props;

  /** Init State */
  const [ filterValues, setFilterValues ] = useState( initFilterValues !== null ? initFilterValues : null );

  /** Update Range filter values */
  useEffect( () => 
  {
    // callback function if filter values not null
    if(
      filterValues !== null 
      && typeof callBackFunction === 'function'
    )
      callBackFunction( { value: filterValues } );

  }, [ filterValues ] );

  return(
    <div className='filter-container categories'>

      {/* Title */}
      <h4 className='picto-title'>
        { pictoTitle }
        { filterLabel }
      </h4>

      {/* Filter type */}
      <DropDownMenuNested
				id={ id + '-dropdownmenunested' }
        labels={[ 'Catégories', 'Sous catégories', 'Sous sous catégories' ] }
        dropDownMenuValues={ Array.isArray( Object.keys( categories ) ) && Object.keys( categories ).length > 0 ? categories : {} }
        initCurrentValues={ filterValues !== null && filterValues.value ? filterValues.value : null }
        callBackFunction={ values => setFilterValues( values ) }
      />

    </div>
  )
}

export default Categories;