/** Redux **/
import { addLoader } from './../../reducers/loaders';

export const callWebservice = (
  loaderID,
  requestID,
  serviceName,
  dispatch,
  clientID,
  params = {},
  callBackFct = null,
  loadingMessage = '',
  method = 'POST'
) => {
  
  // get url request
  const urlRequest = getURLRequestWebservice( serviceName, clientID );

  // get post datas
  const postDatas = getPostDatas( params );

  // add request to loader
  dispatch( addLoader({
    loaderID: loaderID, 
    request: {
      id: requestID,
      url: urlRequest,
      method: method,
      data: postDatas,
      message: loadingMessage,
      callback: callBackFct !== null ? {
        function: callBackFct.function,
        params: callBackFct.params
      } : null
    }
  }));
}

const getURLRequestWebservice = ( serviceName, clientID ) => 
{
  // init url request path
  const urlRequest = process.env.REACT_APP_WEBSERVICE_URL + '/' + clientID + '/' + serviceName + '/';

  return urlRequest;
}

const getPostDatas = ( params ) => 
{
  let postDatas = {};

  for ( const [key, value] of Object.entries( params ) ) {
    switch ( key ) {
      default:
        postDatas[key] = value;
        break;
    }  
  }

  return postDatas;
}