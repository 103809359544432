export const sortByWithNullValue = ( value1, value2 ) => 
{
  // replace null with -1 to sort on start of list if ascending
  value1 = value1 === null ? -1 : value1;
  value2 = value2 === null ? -1 : value2;

  if( value1 === value2 )
    return 0;
  else if( value1 < value2 )
    return -1;
  else
    return 1;
}