/** Dependencies */
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, useParams } from "react-router-dom";
import Button from 'smart-webcomponents-react/button';

/** Components */
import RangeFilterWidget from './../Widgets/FiltersWindowWidgets/Range';
import KeywordsFilterWidget from './../Widgets/FiltersWindowWidgets/Keywords';
import SnippetsFilterWidget from './../Widgets/FiltersWindowWidgets/Snippets';
import UrlFilterWidget from './../Widgets/FiltersWindowWidgets/Url';
import FiltersGroups from './../FiltersGroups/FiltersGroups';
import Categories from './../Widgets/FiltersWindowWidgets/Categories';
import Loader from './../Loader/Loader';

/** Helpers */
import { updateURLSearchParams } from './../../helpers/functions';
import { getPicto } from './../../helpers/pictos';
import { getPredefinedFilterRangesValues } from './../../helpers/datas';
import { getInstanceDatas } from './../../helpers/instance';
import { getDateFromPeriod } from './../../helpers/dates.js';
import { callWebservice } from './../../helpers/webservice/webserviceCaller';

/** SCSS */
import './FiltersWindow.scss';

function FiltersWindow( props )
{
  const {
    openCloseToggler,
    minMaxValues,
    autoCompletedLabelsValues,
    autoCompletedUrlsValues
  } = props;

  /** UseSearchParams Hook */ 
  const [ searchParams, setSearchParams ] = useSearchParams();

  /** Get state from redux store **/
  const userDatas = useSelector( state => state.userDatas.value );

  /** Instance dispatch object **/
	const dispatch = useDispatch();

  /** Get url params */
  const { clientID, location, device } = useParams();

  // get city from data user
  const city = getInstanceDatas( clientID, userDatas ).devLoc.filter( devLoc => devLoc.countryValue === location )[0].cityValue;

  /** Init State */
  const [ currentPeriod, setCurrentPeriod ] = useState( null );
  const [ currentFilters, setCurrentFilters ] = useState( searchParams.get( 'filters' ) !== null ? JSON.parse( searchParams.get( 'filters' ) ) : [] );
  const [ categories, setCategories ] = useState( null );
  const [ reloadKey, setReloadKey ] = useState( 0 );

  // Define colors
  const blueColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-blue' ).trim();
  const grey2Color = getComputedStyle( document.documentElement ).getPropertyValue('--color-grey2').trim();

  // Define min / max values
  const minVolume = ( minMaxValues !== null && minMaxValues['volume.currentVolume'] !== null && minMaxValues['volume.currentVolume']?.min !== undefined ) ? minMaxValues['volume.currentVolume'].min : null;
  const maxVolume = ( minMaxValues !== null && minMaxValues['volume.currentVolume'] !== null  && minMaxValues['volume.currentVolume']?.max !== undefined ) ? minMaxValues['volume.currentVolume'].max : null;
  const minClicks = ( minMaxValues !== null && minMaxValues['gsc.clicks.currentValue'] !== null  && minMaxValues['gsc.clicks.currentValue']?.min !== undefined ) ? minMaxValues['gsc.clicks.currentValue'].min : null;
  const maxClicks = ( minMaxValues !== null && minMaxValues['gsc.clicks.currentValue'] !== null  && minMaxValues['gsc.clicks.currentValue']?.max !== undefined ) ? minMaxValues['gsc.clicks.currentValue'].max : null;
  const minPosition = ( minMaxValues !== null && minMaxValues['serp.position.currentValue'] !== null  && minMaxValues['serp.position.currentValue']?.min !== undefined ) ? minMaxValues['serp.position.currentValue'].min : null;

  // Define predefined values for volume
  const predefinedVolumeRanges = getPredefinedFilterRangesValues( 
    [ '0|10', '10|100', '100|1000', '1000|10000', '10000|100000', '100000|more'], 
    minVolume,
    maxVolume 
  );

  // Define predefined values for clicks
  const predefinedClicksRanges = getPredefinedFilterRangesValues( 
    [ '0|10', '10|100', '100|1000', '1000|10000', '10000|more' ], 
    minClicks,
    maxClicks
  );

  // Define predefined values for positions
  const predefinedPositionRanges = getPredefinedFilterRangesValues( 
    [ '0|3', '4|10', '11|20', '21|100', '100|more' ], 
    minPosition,
    101
  );

  const updateFilter = ( filterTypes, filterName, dataField, filterLabel, filterDatas ) =>
  {
    // get filters param except current filter name
    const filtersParam = currentFilters.filter( elem => elem.filterName !== filterName );

    // update current filters with new filter if filterDatas is not empty
    if( filterDatas !== null )
    {
      // set new filter
      const newFilter = {
        types: filterTypes,
        filterName: filterName,
        dataField: dataField,
        label: filterLabel,
        values: filterDatas
      };
  
      // append new filter to current Filter
      setCurrentFilters( [...filtersParam, newFilter ] );      

    // update current filters without new filter if filterDatas is empty
    } else
      setCurrentFilters( filtersParam );
  }

  const validForm = () => 
  {
    // update url search params
    updateURLSearchParams( 
      { 
        filters: currentFilters.length > 0 ?
          JSON.stringify( currentFilters ) 
          : null
      }, 
      [ searchParams, setSearchParams ] 
    );

    // close window
    openCloseToggler();
  }

  const clearFilters = () => 
  {
    // update url search params
    updateURLSearchParams( { filters: null }, [ searchParams, setSearchParams ] );

    // close window
    openCloseToggler();
  }

  const updateFiltersFromGroup = values => 
  {
    // set current filters with group values
    setCurrentFilters( values );

    // reload filters components with updating keys with random number
    setReloadKey( Math.ceil( Math.random() * 100 ) );
  }

  /** Load categories */
  useEffect(() => 
  { 
    // load data source
    callWebservice(
      'filters-window-loader',
      'filters-window-loader',
      'get-filters-categories',
      dispatch,
      clientID,
      {
        context: 'keywords',
        period: getDateFromPeriod( currentPeriod, null, 'YYYYMMDD' ),
        where: {
          locations: [ location + '|' + city ],
          devices: [ device ]
        }
      },
      {
        function: 'setCategories'
      }
    );

  }, []);

  /** Set current and compare period from searchParams */
  useEffect( () => 
  {
    if( searchParams.get( 'currentPeriod' ) !== null )
      setCurrentPeriod( searchParams.get( 'currentPeriod' ) );
    else
      setCurrentPeriod( '12M' );
      
  }, [ searchParams ]);

  return(
    <Fragment>
      <div className='filters-container'>

        {/* Loader */}
        <Loader 
          loaderID='filters-window-loader'
          loaderStyle={{
            width:'25', 
            stroke: blueColor, 
            viewBox:'-2 -2 42 42'
          }}
          callBackFcts={{
            setCategories: results => results?.categories !== undefined ? setCategories( results.categories ) : false
          }}
        />

        <div className='filters-widgets-container-top'>

          {/* Range Volume */}
          { categories !== null ?
            <RangeFilterWidget 
              key={ reloadKey + '-volume' }
              id="filter-range-volume"
              pictoTitle={ getPicto( 'MagnifyingGlass', { size: "1rem", weight: 'bold', color: grey2Color } ) }
              filterLabel="Volume"
              initFilterValues={ 
                currentFilters !== null 
                && currentFilters.filter( elem => elem.filterName === 'volume' && elem.values ).length === 1 ? 
                  currentFilters.filter( elem => elem.filterName === 'volume' )[0].values 
                  : null
              }
              callBackFunction={ filterDatas => updateFilter(
                {
                  value: 'range',
                  variation: 'variation'
                },
                'volume',
                'volume',
                'Volume',
                filterDatas 
              )}
              sliderMinValue={ minVolume !== null ? minVolume : 0 }
              sliderMaxValue={ maxVolume !== null ? maxVolume : 100 }
              dropDownMenuRangeValues={ predefinedVolumeRanges }        
              dropDownMenuEvolValues={[
                { label: 'Augmente', value: 'increase' },
                { label: 'Neutre', value: 'constant' },
                { label: 'Baisse', value: 'decrease' }
              ]} 
            />
            : null
          }

          {/* Range Clicks */}
          { categories !== null ?
            <RangeFilterWidget 
              key={ reloadKey + '-click' }
              id="filter-range-clicks"
              pictoTitle={ getPicto( 'Mouse', { size: "1rem", weight: 'bold', color: grey2Color } ) }
              filterLabel="Clics"
              initFilterValues={ 
                currentFilters !== null 
                && currentFilters.filter( elem => elem.filterName === 'clicks' && elem.values ).length === 1 ? 
                  currentFilters.filter( elem => elem.filterName === 'clicks' )[0].values 
                  : null
              }
              callBackFunction={ filterDatas => updateFilter(
                {
                  value: 'range',
                  variation: 'variation'
                },
                'clicks',
                'gsc.clicks',
                'Clics',
                filterDatas 
              )}
              sliderMinValue={ minClicks !== null ? minClicks : 0 }
              sliderMaxValue={ maxClicks !== null ? maxClicks : 100 }
              dropDownMenuRangeValues={ predefinedClicksRanges }
              dropDownMenuEvolValues={[
                { label: 'Augmente', value: 'increase' },
                { label: 'Neutre', value: 'constant' },
                { label: 'Baisse', value: 'decrease' }
              ]} 
            />
            : null
          }

          {/* Range Position */}
          { categories !== null ?
            <RangeFilterWidget 
              key={ reloadKey + '-position' }
              id="filter-range-position"
              pictoTitle={ getPicto( 'ArrowsVertical', { size: "1rem", weight: 'bold', color: grey2Color } ) }
              filterLabel="Position"
              initFilterValues={ 
                currentFilters !== null 
                && currentFilters.filter( elem => elem.filterName === 'position' && elem.values ).length === 1 ? 
                  currentFilters.filter( elem => elem.filterName === 'position' )[0].values 
                  : null
              }
              callBackFunction={ filterDatas => updateFilter(
                {
                  value: 'range-position',
                  variation: 'variation',
                  movement: 'movement'
                },
                'position',
                'serp.position',
                'Position',
                filterDatas 
              )}
              sliderMinValue={ minPosition !== null ? minPosition : 0 }
              sliderMaxValue={ 101 }
              dropDownMenuRangeValues={ predefinedPositionRanges }        
              dropDownMenuEvolValues={[
                { label: 'Augmente', value: 'increase' },
                { label: 'Neutre', value: 'constant' },
                { label: 'Baisse', value: 'decrease' }
              ]} 
              dropDownMenuMoveValues={[
                { label: 'Entrées', value: 'in' },
                { label: 'Sorties', value: 'out' }
              ]}            
            />
            : null
          }
          
        </div>

        <div className='filters-widgets-container-bottom'>

          {/* Keywords */}
          { categories !== null ?
            <KeywordsFilterWidget 
              key={ reloadKey + '-keyword' }
              id="filter-keywords"
              pictoTitle={ getPicto( 'PenNib', { size: "1rem", weight: 'bold', color: grey2Color } ) }
              filterLabel="Mot-clé"
              autoCompletedValues={ autoCompletedLabelsValues }
              initFilterValues={ 
                currentFilters !== null 
                && currentFilters.filter( elem => elem.filterName === 'label' && elem.values ).length === 1 ? 
                  currentFilters.filter( elem => elem.filterName === 'label' )[0].values 
                  : null
              }
              callBackFunction={ filterDatas => updateFilter(
                {
                  value: 'string'
                },
                'label',
                'label',
                'Mot-clé',
                filterDatas.value.value !== '' ? filterDatas : null 
              )}
            /> 
            : null
          }

          {/* Categories */}
          { categories !== null ?
            <Categories 
              key={ reloadKey + '-categories' }
              id="filter-categories"
              pictoTitle={ getPicto( 'ListChecks', { size: "1rem", weight: 'bold', color: grey2Color } ) }
              filterLabel="Catégories"
              categories={ categories !== null ? categories : {} }
              initFilterValues={ 
                currentFilters !== null 
                && currentFilters.filter( elem => elem.filterName === 'categories' && elem.values ).length === 1 ? 
                  currentFilters.filter( elem => elem.filterName === 'categories' )[0].values 
                  : null
              }  
              callBackFunction={ filterDatas => updateFilter(
                {
                  value: 'categories'
                },
                'categories',
                'categories',
                'Catégories',
                filterDatas?.value?.values !== undefined && filterDatas.value.values.length > 0 ? filterDatas : null
              )}
            />
            : null
          }
          
          {/* Snippets */}
          { categories !== null ?
            <SnippetsFilterWidget 
              key={ reloadKey + '-snippets' }
              id="filter-snippets"
              pictoTitle={ getPicto( 'Seal', { size: "1rem", weight: 'bold', color: grey2Color } ) }
              filterLabel="Snippets"    
              initFilterValues={ 
                currentFilters !== null 
                && currentFilters.filter( elem => elem.filterName === 'snippets' && elem.values ).length === 1 ? 
                  currentFilters.filter( elem => elem.filterName === 'snippets' )[0].values 
                  : null
              }  
              callBackFunction={ filterDatas => updateFilter(
                {
                  value: 'snippets'
                },
                'snippets',
                'serp.snippets',
                'Snippets',
                filterDatas.value.values.length > 0 ? filterDatas : null
              )}
            />
            : null
          }

          {/* Url */}
          { categories !== null ?
            <UrlFilterWidget 
              key={ reloadKey + '-url' }
              id="filter-url"
              pictoTitle={ getPicto( 'LinkSimple', { size: "1rem", weight: 'bold', color: grey2Color } ) }
              filterLabel="Url"
              autoCompletedValues={ autoCompletedUrlsValues }
              initFilterValues={ 
                currentFilters !== null 
                && currentFilters.filter( elem => elem.filterName === 'url' && elem.values ).length === 1 ? 
                  currentFilters.filter( elem => elem.filterName === 'url' )[0].values 
                  : null
              }
              callBackFunction={ filterDatas => updateFilter(
                {
                  value: 'string'
                },
                'url',
                'serp.url',
                'URL',
                filterDatas.value.value !== '' ? filterDatas : null
              )}
            />
            : null
          }

        </div>

        <div className='confirm-container'>
          <Button 
            className='flat fill' 
            onClick={ () => validForm() }        
          >Filtrer</Button>
          
          <Button 
            className='flat empty'
            onClick={ () => clearFilters() }
          >Effacer filtres</Button>
        </div>
      </div>

      {/* Groupes de filtres */}
      <FiltersGroups
        currentFilters={ currentFilters }
        callBackFunction={ values => updateFiltersFromGroup( values ) }
      />

    </Fragment>
  )
}

export default FiltersWindow;