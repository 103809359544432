/** Dependencies */
import { Chart } from 'smart-webcomponents-react/chart';

/** Helpers */
import { getMinMaxFromArrayObject } from './../../helpers/functions';

/** SCSS */
import './InsightsCompareColumnChart.scss';

function InsightsCompareStepLineChart( props )
{
  const {
    id,
    datas,
    dataField,
    flip,
    xAxisPosition,
    constantValue
  } = props;
  
  // Define positive and negitive colors
  const compareSerieColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-grey2' ).trim();
  const constantSerieColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-yellow' ).trim();
  const positiveColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-orange' ).trim();
  const negativeColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-blue' ).trim();

  // get min / max values from compare and current datas
  const minMaxValues = getMinMaxFromArrayObject( datas, ['current', 'compare'] );

  // add top line values in datas
  const customDatas = datas.map( data => ({ 
    ...data, 
    ...{ constant: constantValue }
  }));

  // define column width
  const columnWidth = 30;

  // Define settings for chart
  const settings = {
    caption: '',
    description: '',
    showLegend: false,
    animation: 'none',
    showBorderLine: false,
    showToolTips: true,
    backgroundColor: 'white',
    columnSeriesOverlap: true,
    toolTipFormatFunction: ( value, itemIndex, serie, group, xAxisValue, xAxis ) => xAxisValue + ' : ' +  Intl.NumberFormat('fr-FR').format( value ),
    padding: {
      left: ( 
        parseInt( minMaxValues.global.max ).toString().length < 3 ? 
          8
          : parseInt( minMaxValues.global.max ).toString().length > 3 ?
            2 
            : 1 
      ),
      top: 0,
      right: 25,
      bottom: 0
    },
    titlePadding: {
      left: 0,
      top: 0,
      right: 0,
      bottom: 0
    },
    dataSource: customDatas,
    xAxis: {
      dataField: dataField,
      position: xAxisPosition !== undefined ? xAxisPosition : 'bottom',
      line: {
        visible: false
      },
      tickMarks: {
        visible: false
      },
      labels: {
        class: 'x-axis-labels',
        offset: {
          y: flip?.valueAxis !== undefined ? -5 : 5
        }
      },
      gridLines: {
        visible: false
      }
    },
    seriesGroups: [    
      {
        type: 'line',
        columnsMinWidth: columnWidth,
        columnsMaxWidth: columnWidth,
        valueAxis: {
          visible: false,
          minValue: minMaxValues?.global?.min !== undefined ? minMaxValues?.global?.min : 0,
          maxValue: minMaxValues?.global?.max !== undefined ? minMaxValues?.global?.max : 100,
          flip: flip?.valueAxis !== undefined ? flip.valueAxis : false,
        },
        series:[
          { 
            dataField: 'constant',
            lineColor: constantSerieColor,
            lineWidth: 1,
            opacity: 0.75
          }           
        ]
      },
      {
        type: 'stepline',
        columnsMinWidth: columnWidth,
        columnsMaxWidth: columnWidth,
        valueAxis: {
          visible: false,
          minValue: minMaxValues?.global?.min !== undefined ? minMaxValues?.global?.min : 0,
          maxValue: minMaxValues?.global?.max !== undefined ? minMaxValues?.global?.max : 100,
          flip: flip?.valueAxis !== undefined ? flip.valueAxis : false,
        },
        series:[
          { 
            dataField: 'compare',
            lineColor: compareSerieColor,
            lineWidth: 2,
            opacity: 0.25
          }           
        ]
      },
      {
        type: 'stepline',
        columnsMinWidth: columnWidth,        
        columnsMaxWidth: columnWidth,        
        valueAxis: {
          visible: false,
          minValue: minMaxValues?.global?.min !== undefined ? minMaxValues?.global?.min : 0,
          maxValue: minMaxValues?.global?.max !== undefined ? minMaxValues?.global?.max : 100,
          flip: flip?.valueAxis !== undefined ? flip.valueAxis : false,
        },
        series:[
          { 
            dataField: 'current',
            lineWidth: 2,
            opacity: 0.8,
            colorFunction: ( value, itemIndex ) => 
            {
              // set color
              let fillColor = positiveColor;
              if( datas.length > 1 )
              {
                if( itemIndex === 0 && value < datas[1].current )
                  fillColor = positiveColor;
                else if( itemIndex === 0 && value > datas[1].current )
                  fillColor = negativeColor;
                else if ( itemIndex > 0 && datas[itemIndex - 1] && value < datas[itemIndex - 1].current )
                  fillColor = positiveColor;
                else if ( itemIndex > 0 && datas[itemIndex - 1] && value > datas[itemIndex - 1].current )
                  fillColor = negativeColor;
              }

              // reverse color if chart is fliped
              if( flip?.valueAxis === true && fillColor === negativeColor )
                fillColor = positiveColor;
              else if( flip?.valueAxis === true && fillColor === positiveColor )
                fillColor = negativeColor;

              // set return 
              return ({ 
                fillColor: fillColor, 
                fillColorSelected: fillColor, 
                lineColor: fillColor 
              });
            }  
          }
        ]
      }      
    ]
  };

  return(
    <div className="insights-bar-chart">
      <Chart
				id = { id + '-chart' }
        {...settings}
			/>
    </div>
  )
}

export default InsightsCompareStepLineChart;