/** Helpers **/
import { callWebservice } from './webserviceCaller';

export default class wsKeywordsGroups
{
  constructor(
    loaderID,
    dispatch,
    selectedInstance,
    userId
  ){
    this.loaderID = loaderID;
    this.dispatch = dispatch;    
    this.selectedInstance = selectedInstance;
    this.userId = userId;
  }

  getKeywordsGroups = ( 
    callBackFct = null
  ) => 
  {
    this.exec(
      {
        userId: this.userId
      },
      callBackFct,
      'get'
    );
  }

  addKeywordsGroup = ( 
    keywordsGroupName,
    keywordsGroupValue,
    callBackFct = null
  ) => 
  {
    this.exec(
      {
        userId: this.userId,
        keywordsGroupName: keywordsGroupName,
        keywordsGroupValue: keywordsGroupValue
      },
      callBackFct,
      'add'
    );
  }
  
  exec = (
    datas, 
    callBackFct = null,
    actionType
  ) => 
  {
    callWebservice( 
      this.loaderID,
      actionType + '-keywords-groups',
      actionType + '-keywords-groups',
      this.dispatch,
      this.selectedInstance,
      datas,
      callBackFct.function !== null ? {
        function: callBackFct.function,
        params: callBackFct?.params !== undefined ? callBackFct.params : null
      } : null
    );
  }
}