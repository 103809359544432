/** Helpers */
import { stringToObjectPath, isValidJSON } from './functions.js';

/**
 * Filter data array with filters array
 * @param {Array} datas Array of data from webservice
 * @param {Array} filters Array of filters
 * @returns Array
 */
export const filterDatas = ( datas, filters ) => 
{
  let filtersDatas = datas;

  if( 
    datas !== null 
    && filters !== null
    && Array.isArray( datas )
    && Array.isArray( filters ) 
  ){
    // clone original data source
    let cloneDatas = [ ...datas ];

    // for each filter
    filters.forEach(  filter => 
    {
      // get filter type
      const filterValues = filter.values;

      // for each type
      Object.keys( filterValues ).forEach( filterValueField => 
      {
        // filter clone datas
        cloneDatas = [ ...cloneDatas ].filter( elem => 
        {
          // get filter type
          const filterType = filter.types[ filterValueField ];

          // get filter value
          const filterValue = filter.values[ filterValueField ];

          // get data value
          let dataValue = stringToObjectPath( elem, filter.dataField );

          // parse dataValue to json if is string
          if( typeof dataValue === 'string' && isValidJSON( dataValue ) )
            dataValue = JSON.parse( dataValue );

          if( 
            dataValue !== undefined 
            && dataValue !== null 
            && filterValue !== null
            && applyFilter( filterType, dataValue[filterValueField], filterValue ) === true
          )
            return true;
          else
            return false;
        });   
      });
    });

    // set filters data with filters clone datas
    filtersDatas = cloneDatas;
  }

  return filtersDatas;
}

/**
 * Apply filters rules by type
 * @param {String} filterType filter type
 * @param {Any} dataValue value to filtered
 * @param {Any} filterValue value for filtering
 * @returns boolean
 */
export const applyFilter = ( filterType, dataValue, filterValue ) => 
{
  let response = false;

  switch ( filterType )
  {
    case 'range':
      response = rangeFilter( dataValue, filterValue );
      break;

    case 'range-position':
      response = rangePositionFilter( dataValue, filterValue );
      break;

    case 'variation':
      response = variationFilter( dataValue, filterValue );
      break;

    case 'string':
      response = stringFilter( dataValue, filterValue );
      break;

    case 'snippets':
      response = snippetFilter( dataValue, filterValue );
      break;

    case 'categories':
      response = categoriesFilter( dataValue, filterValue );
      break;
  
    default:
      response = dataValue === filterValue;
      break;
  }  

  return response;
}

/**
 * Filter datas by range values
 * @param {Array} dataValue 
 * @param {Array} filterValue [0,10]
 * @returns boolean
 */
export const rangeFilter = ( dataValue, filterValue ) => 
{
  if( 
    filterValue[0] !== undefined
    && filterValue[1] !== undefined
    && dataValue !== null
    && filterValue[0] <= dataValue 
    && dataValue <= filterValue[1] 
  )
    return true;
  else
    return false;
}

/**
 * Filter datas by range values with specific position case NaN
 * @param {Array} dataValue 
 * @param {Array} filterValue [0,10]
 * @returns boolean
 */
export const rangePositionFilter = ( dataValue, filterValue ) => 
  {
    if( 
      (
        filterValue[0] !== undefined
        && filterValue[1] !== undefined
        && dataValue !== null
        && filterValue[0] <= dataValue 
        && dataValue <= filterValue[1] 
      ) || (
        isNaN( dataValue )
        && filterValue[0] === 100
        && filterValue[1] === 101
      )
    )
      return true;
    else
      return false;
  }

/**
 * Filter datas with variation
 * @param {Array} dataValue 
 * @param {String} filterValue increase | decrease | constant
 * @returns 
 */
export const variationFilter = ( dataValue, filterValue ) =>
{
  if( filterValue === 'increase' && dataValue > 0 ) return true;
  else if ( filterValue === 'decrease' && dataValue < 0 ) return true;
  else if( filterValue === 'constant' && dataValue === 0 ) return true;
  else return false;
}

/**
 * Filters datas on string with reg exp
 * @param {Array} dataValue 
 * @param {Object} { value: moulin, filterStringType: equalsIgnoreCase}
 * @returns 
 */
export const stringFilter = ( dataValue, filterValue ) => 
{
  // get value
  const value = filterValue.value;

  // get filterStringType
  const filterStringType = filterValue.filterStringType;

  if( filterStringType === 'equalsIgnoreCase' )
    return new RegExp( '^' + value + '$', 'gi' ).test( dataValue );
  else if( filterStringType === 'startsWithIgnoreCase' )
    return new RegExp( '^' + value, 'gi' ).test( dataValue );
  else if( filterStringType === 'doesNotContainIgnoreCase' )
    return !new RegExp( value, 'gi' ).test( dataValue );
  else
    return new RegExp( value, 'gi' ).test( dataValue );
}

export const snippetFilter = ( dataValue, filterValue ) =>
{
  // get values
  const values = filterValue.values.map( elem => elem.value );

  // get filterOn
  const filterOn = filterValue.filterOn;

  if( dataValue !== null )
  {
    if( filterOn === 'onSerp' )
      return values.filter( value => 
        ( dataValue.valueMe !== null && dataValue.valueMe.split( '|' ).includes( value ) )
        || 
        ( dataValue.valueOthers !== null && dataValue.valueOthers.split( '|' ).includes( value ) ) 
      ).length > 0;
    else if( filterOn === 'notOnSerp' )
      return (
        values.filter( value => 
          ( dataValue.valueMe !== null && dataValue.valueMe.split( '|' ).includes( value ) )
          || 
          ( dataValue.valueOthers !== null && dataValue.valueOthers.split( '|' ).includes( value ) )        
        ).length === 0
        ||
        ( dataValue.valueOthers === null && dataValue.valueOthers === null ) 
      );
    else if( filterOn === 'targetRank' )
      return values.filter( value => dataValue.valueMe !== null && dataValue.valueMe.split( '|' ).includes( value ) ).length > 0;
    else if( filterOn === 'targetNotRank' )
      return (
        values.filter( value => dataValue.valueMe !== null && dataValue.valueMe.split( '|' ).includes( value ) ).length === 0 
        && values.filter( value => dataValue.valueOthers !== null && dataValue.valueOthers.split( '|' ).includes( value ) ).length > 0
      );
  }
}

export const categoriesFilter = ( dataValue, filterValue ) => 
{
  return filterValue.values.filter( elem => dataValue.startsWith( elem ) ).length === 1;
}