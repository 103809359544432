/** Helpers **/
import { callWebservice } from './webserviceCaller';

export default class wsLogin
{
  constructor(
    loaderID,
    dispatch
  ){
    this.loaderID = loaderID;
    this.dispatch = dispatch;    
  }

  checkLogin = ( 
    userDatas,
    callBackFct = null
  ) => 
  {
    this.exec(
      userDatas,
      callBackFct,
      'check'
    );
  }
  
  exec = (
    datas, 
    callBackFct = null,
    actionType
  ) => 
  {
    callWebservice( 
      this.loaderID,
      actionType + '-login',
      actionType + '-login',
      this.dispatch,
      datas.email,
      datas,
      callBackFct.function !== null ? {
        function: callBackFct.function,
        params: callBackFct?.params !== undefined ? callBackFct.params : null
      } : null
    );
  }
}