/** SCSS */
import './Login.scss';

function Login()
{
  return(
    <div className="home">
      <h1>Bienvenue sur IMPAKKT</h1>      
    </div>
  )
}

export default Login;