/** Dependencies */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

/** Reducers */
import { offToggler } from './../../../reducers/togglers';

/** Components */
import InstancesList from './../../InstancesList/InstancesList';

/** Helpers */
import { getPicto } from './../../../helpers/pictos';

/** SCSS */
import './BurgerMenu.scss';

function BurgerMenu()
{
	/** Instance dispatch object **/
	const dispatch = useDispatch();

	/** Get state from redux store **/
  const togglers = useSelector( state => state.togglers.value );

	/** Init State */
	const [ currentContentName, setCurrentContentName ] = useState( 'instancesList' );
  const [ currentContent, setCurrentContent ] = useState( <InstancesList /> );

	/** Add event click on document to close the window if not active */
  useEffect( () => 
	{
		// close function on click
		const handleClick = ( e ) => 
		{
			if( 
				e.target.closest( '#burger-menu' ) === null
				&& e.target.closest( '#main-menu' ) === null
			){
				// set window open state to false
				dispatch( offToggler( 'burgerMenuIsOpened' ) );
			} 
		};
	
		// add click listener on document
		document.addEventListener( 'click', e => handleClick( e ) );    
		return () => {
			document.removeEventListener( 'click', e => handleClick( e ) );
		};
		
	}, [] );

	useEffect( () => 
	{
		switch ( currentContentName ) {
			default:
				setCurrentContent( <InstancesList /> );
				break;
		}

	}, [ currentContentName ] );

  return(
    <div id='burger-menu' className={ 'burger-menu ' + ( togglers.burgerMenuIsOpened ? 'open' : 'close' ) }>
			
			{/* BurgerMenu nav */}
			<div className='burger-menu-nav'>
				<button 					
					onClick={ () => dispatch( offToggler( 'burgerMenuIsOpened' ) ) }
				>{ getPicto( 'X', { size: 25, color: 'white' } ) }</button>

				<button 
					className={ currentContentName === 'instancesList' ? 'selected' : '' }
					onClick={ () => setCurrentContentName( 'instancesList' ) }
				>{ getPicto( 'ListDashes', { size: 25, color: 'white' } ) }</button>
				
			</div>

			{/* BurgerMenu container */}
			<div className='burger-menu-container'>
				{ currentContent }
			</div>
		</div>
  )
}

export default BurgerMenu;