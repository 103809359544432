/** Dependencies */
import { useSelector, useDispatch } from 'react-redux';

/** Reducers */
import { onToggler } from './../../reducers/togglers';

/** Helpers */
import { getPicto } from "./../../helpers/pictos";

/** SCSS */
import './FilterRemoverSnippets.scss';

function FilterRemoverSnippets( props )
{
  const{ 
    label,
    field,
    value,
    filterName,
    callBackFunction
  } = props;

  /** Instance dispatch object **/
	const dispatch = useDispatch();
  
  /** Get state from redux store **/
  const togglers = useSelector( state => state.togglers.value );

  // Define increase and decrease colors
  const blueColor = getComputedStyle( document.documentElement ).getPropertyValue('--color-blue').trim();

  return(
    <div 
      className="filter snippets"
      id={ 'filter-' + filterName + '-' + field }
    >
      <div 
        className="label" 
        title={ label }
        onClick={ !togglers[ 'filters-windowbutton' ] ? () => dispatch( onToggler( 'filters-windowbutton' ) ) : null }
      >{ label }</div>
      <div className="pictos">
        {
          value.values.map( ( snippet, index ) => 
            <div 
              key={ index }
              className="remove-button" 
              title={ snippet.label }
            >
              { getPicto( snippet.value, { size: '1rem', fill: blueColor } ) }
              <button 
                onClick={ () => callBackFunction( filterName, field, value, snippet.value ) }
              >{ getPicto( 'X', { size: '1rem', fill: blueColor, weight: 'bold' } ) }</button>
            </div>          
          )
        }
      </div>
    </div>
  )
}

export default FilterRemoverSnippets;