/** Dependencies */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import Button from 'smart-webcomponents-react/button';

/** Components */
import Loader from './../../components/Loader/Loader';
import DropDownMenu from './../Filters/DropDownMenu';

/** Helpers */
import { isValidJSON, sortArrayByObjectField, deepEqual } from './../../helpers/functions';
import wsFiltersGroups from './../../helpers/webservice/wsFiltersGroups.class';

/** SCSS */
import './FiltersGroups.scss';

function FiltersGroups( props )
{
  const {
    currentFilters,
    callBackFunction
  } = props;

  /** Instance dispatch object **/
	const dispatch = useDispatch();

  /** Define colors */
  const loaderColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-blue' ).trim();

  /** Init State */
  const [ customFilters, setCustomFilters ] = useState( null )
  const [ filtersList, setFiltersList ] = useState( null )
  const [ currentFiltersGroup, setCurrentFiltersGroup ] = useState( null )
  const [ newFiltersGroupName, setNewFiltersGroupName ] = useState( null )
  const [ messageError, setMessageError ] = useState( null )
  const [ reloadKey, setReloadKey ] = useState( 0 )

  /** Get state from redux store **/
  const userDatas = useSelector( state => state.userDatas.value );

  // get instance infos from url
  const { clientID } = useParams();

  /** reset window filters values */
  const updateFilters = value => 
  {
    if( 
      isValidJSON( value ) 
      && Array.isArray( JSON.parse( value ) ) 
      && typeof callBackFunction === 'function' 
    )
      callBackFunction( JSON.parse( value ) );
  }

  const addNewFilter = () => 
  {
    if( 
      newFiltersGroupName === null 
      || (
        newFiltersGroupName !== null
        && newFiltersGroupName.trim() === '' 
      )
    ){
      // set message error
      setMessageError( 'Merci de renseigner un nom de groupe.' );

      // remove message error after 5s
      setTimeout( () => setMessageError( null ), 5000 );

    } else if( currentFilters.length === 0 ) {

      // set message error
      setMessageError( 'Merci de  sélectionner des filtres.' );

      // remove message error after 5s
      setTimeout( () => setMessageError( null ), 5000 );

    } else if( filtersList.map( elem => elem.label ).includes( newFiltersGroupName ) ) {

      // set message error
      setMessageError( 'Ce nom de groupe de filtres est déjà existant.' );

      // remove message error after 5s
      setTimeout( () => setMessageError( null ), 5000 );

    } else {

      // reset message error
      setMessageError( null );

      // save custom filter
      new wsFiltersGroups(
        'filters',
        dispatch,
        clientID,
        userDatas.id
      ).addFiltersGroup( 
        newFiltersGroupName,
        currentFilters,
        { function: 'addCustomFilters' } 
      );
      
      // reset new filters group name input
      setNewFiltersGroupName( null );
    }
  }

  /** Load filters */
  useEffect( () => 
  {
    new wsFiltersGroups(
      'filters',
      dispatch,
      clientID,
      userDatas.id
    ).getFiltersGroups( { function: 'setCustomFilters' } );

  }, []);

  /** update filters list after custom filters loading */
  useEffect( () => 
  {
    // get predefined filters
    let predefinedFiltersGroups = [];
    if( userDatas?.predefinedFiltersGroups !== undefined )
      predefinedFiltersGroups = sortArrayByObjectField( userDatas.predefinedFiltersGroups, 'filtersGroupName' ).map( elem => ({ 
        label: elem.filtersGroupName, 
        value: elem.filtersGroupValue,
        group: 'Groupes de filtres prédéfinis'
      }));

    if( Array.isArray( customFilters ) )
      setFiltersList([
        ...predefinedFiltersGroups,
        ...sortArrayByObjectField( customFilters, 'filtersGroupName' ).map( elem => ({ 
          label: elem.filtersGroupName, 
          value: elem.filtersGroupValue,
          group: 'Groupes de filtres personnalisés'
        }))
      ]);
    else
      setFiltersList( [ ...predefinedFiltersGroups ] );

  }, [ customFilters ]);

  useEffect( () => 
  {
    // set current filter group value
    if( 
      filtersList !== null 
      && filtersList.find( elem => deepEqual( JSON.parse( elem.value ), currentFilters ) ) !== undefined 
    )
      setCurrentFiltersGroup( filtersList.find( elem => deepEqual( JSON.parse( elem.value ), currentFilters ) ).value );
    else
      setCurrentFiltersGroup( [] );

  }, [ currentFilters, filtersList ]);

  useEffect( () => 
  {
    // reload filters groups drop down menu
    if( currentFiltersGroup !== null )
      setReloadKey( Math.ceil( Math.random() * 100 ) );

  }, [ currentFiltersGroup ]);

  return(
    <div className='filters-groups-container'>

      <Loader 
        loaderID="filters" 
        loaderStyle={{
          width:'25', 
          stroke: loaderColor, 
          viewBox:'-2 -2 42 42'
        }}
        callBackFcts={{
          setCustomFilters: results => setCustomFilters( results !== undefined ? results : null ),
          addCustomFilters: results => setCustomFilters([ 
            ...customFilters, 
            { 
              id: results.filterId,
              filtersGroupName: results.filtersGroupName,
              filtersGroupValue: JSON.stringify( results.filtersGroupValue ) 
            } 
          ])
        }}
      />      

      <h4 className='picto-title'>Filtres enregistrés</h4>

      <DropDownMenu
        key={ reloadKey }
        id='filters-groups-list'
        label="Groupes de filtres"
        dropDownMenuValues={ filtersList }
        grouped={ true }        
        initCurrentValues={ currentFiltersGroup }
        callBackFunction={ value => updateFilters( value ) }
      />

      {/* <Button 
        className='flat empty' 
        onClick={ () => validForm() }        
      >Gérer</Button> */}

      <h4 className='picto-title'>Enregistrer les filtres actuels</h4>

      {/* Input */}
      <input 
        id='filters-groups-name'
        placeholder='Nom groupe...'
        value={ newFiltersGroupName !== null ? newFiltersGroupName : '' }
        onChange={ e => e?.target?.value !== undefined ? setNewFiltersGroupName( e.target.value ) : null }
        onKeyDown={ e => e.code === 'Enter' ? addNewFilter() : null }
      />

      <div className='submit-container'>
        <Button 
          className='flat fill' 
          onClick={ () => addNewFilter() }
        >Enregistrer</Button>
        <p className='message-error'>{ messageError }</p>
      </div>

    </div>
  );
}

export default FiltersGroups;