/** Dependencies */
import { useSearchParams } from "react-router-dom";

/** Components */
import PeriodWindow from './../PeriodWindow/PeriodWindow';
import WindowButton from '../WindowButton/WindowButton';

/** Helpers */
import { getPicto } from './../../helpers/pictos';
import { getDateFromPeriod, formatDate } from './../../helpers/dates';

/** SCSS */
import './PeriodCalendar.scss';

function PeriodCalendar()
{
  /** Instance useSearchParams Hook */
  let [ searchParams ] = useSearchParams();

  /** Define colors */
  const grey2Color = getComputedStyle( document.documentElement ).getPropertyValue( '--color-grey2' ).trim();

  /** Get search params */
  const currentPeriod = searchParams.get( 'currentPeriod' ) !== null ? searchParams.get( 'currentPeriod' ) : '12M' ;
  const comparePeriod = searchParams.get( 'comparePeriod' ) !== null ? searchParams.get( 'comparePeriod' ) : 'N-1' ;

  // Get dates and format period dates
  const currentStartDate = formatDate( getDateFromPeriod( currentPeriod ).startDate, 'YYYY-MM-DD', 'DD/MM/YYYY' );
  const currentEndDate = formatDate( getDateFromPeriod( currentPeriod ).endDate, 'YYYY-MM-DD', 'DD/MM/YYYY' );
  const compareStartDate = formatDate( getDateFromPeriod( currentPeriod, comparePeriod ).startDate, 'YYYY-MM-DD', 'DD/MM/YYYY' );
  const compareEndDate = formatDate( getDateFromPeriod( currentPeriod, comparePeriod ).endDate, 'YYYY-MM-DD', 'DD/MM/YYYY' );
  
  return(
    <div className="period-calendar">

      {/* Period Informations */}
      <div className="period-infos">
        <div className="current">{ currentStartDate + ' - ' + currentEndDate }</div>
        { comparePeriod !== 'none' ? <div className="compare">Comparé à <span>{ compareStartDate + ' - ' + compareEndDate }</span></div> : null }
      </div>

      {/* Button and period selector */}
      <WindowButton
        id="period-calendar"
        className="period-calendar"
        windowContent={ <PeriodWindow />  } 
        buttonContent={ getPicto( 'Control', { size: '1.3rem', weight: 'bold', fill: grey2Color } ) }
      />
      
    </div>
  );
}

export default PeriodCalendar;