/** Dependencies */
import { useRef, useEffect, useState } from 'react';
import { DropDownList, ListItem } from 'smart-webcomponents-react/dropdownlist';
import { Button } from 'smart-webcomponents-react/button';
import { DateTimePicker } from 'smart-webcomponents-react/datetimepicker';
import { useSearchParams } from "react-router-dom";
import moment from 'moment';
import 'moment/locale/fr';

/** Helpers */
import { updateURLSearchParams } from './../../helpers/functions';
import { 
  getDateFromPeriod, 
  formatDate, 
  getPeriodsDiffFromDate, 
  getGranularityStatus 
} from './../../helpers/dates';

/** SCSS */
import './PeriodWindow.scss';

function PeriodWindow( props )
{
  /** Get props */
  const { openCloseToggler } = props;

  /** Set Datas source for select menu */
  const currentDataSource = [
    { label: '12 derniers mois', value: '12M', date: getDateFromPeriod( '12M' ) },
    { label: '6 derniers mois', value: '6M', date: getDateFromPeriod( '6M' ) },
    { label: '3 derniers mois', value: '3M', date: getDateFromPeriod( '3M' ) },
    { label: '12 dernières semaines', value: '12S', date: getDateFromPeriod( '12S' ) },
    { label: '4 dernières semaines', value: '4S', date: getDateFromPeriod( '4S' ) },
    { label: 'Période personnalisée (sem.)', value: 'custom' },
    { label: 'Période personnalisée (mois)', value: 'custom' }
  ];

  const compareDataSource = [
    { label: 'Comparé à période précédente', value: 'P-1' },
    { label: 'Comparé à année précédente', value: 'N-1' },
    { label: 'Comparé à période personnalisée', value: 'custom' },
    { label: 'Sans comparaison', value: 'none' }
  ];

  /** Define custom indexes */
  const currentCustomWeekIndex = 5;
  const currentCustomMonthIndex = 6;
  const compareCustomIndex = 2;

  /** Init Refs */
  const dateTimePickerCurrentStart = useRef();
  const dateTimePickerCurrentEnd = useRef();
  const dateTimePickerCompareStart = useRef();
  const dateTimePickerCompareEnd = useRef(); 
  const currentPeriodSelectMenu = useRef();
  const comparePeriodSelectMenu = useRef();

  /** Get instance infos from url */
  let [ searchParams, setSearchParams ] = useSearchParams();

  /** Init State */
  const [ currentPeriod, setCurrentPeriod ] = useState( null );
  const [ comparePeriod, setComparePeriod ] = useState( null );
  const [ currentGranularity, setCurrentGranularity ] = useState( 'month' );
      
  const handleChangeDatePicker = ( periodType, calendarType ) => 
  {
    // update calendars period
    updateCalendarsPeriod( calendarType );

    // update preselected period
    updateSelectedPeriod( periodType );
  }

  const updateCalendarsPeriod = ( calendarType ) => 
  {
    // get dates
    const currentStart = dateTimePickerCurrentStart.current.getDate().getTime();
    const currentEnd = dateTimePickerCurrentEnd.current.getDate().getTime();
    const compareStart = dateTimePickerCompareStart.current.getDate().getTime();
    const compareEnd = dateTimePickerCompareEnd.current.getDate().getTime();

    // set start of week or start of month
    dateTimePickerCurrentStart.current.setDate( moment( currentStart ).startOf( currentGranularity ).valueOf() );
    dateTimePickerCurrentEnd.current.setDate( moment( currentEnd ).endOf( currentGranularity ).valueOf() );
    dateTimePickerCompareStart.current.setDate( moment( compareStart ).startOf( currentGranularity ).valueOf() );
    dateTimePickerCompareEnd.current.setDate( moment( compareEnd ).endOf( currentGranularity ).valueOf() );

    // set dates limit
    const currentStartLimit = moment( currentEnd ).startOf( currentGranularity ).valueOf();
    const currentEndLimit = moment( currentStart ).endOf( currentGranularity ).valueOf();

    // current start date timestamp > current end date timestamp with start date modification calendarType
    if( calendarType === 'start' && currentStart > currentStartLimit ) 
      dateTimePickerCurrentEnd.current.setDate( moment( currentEndLimit ).endOf( currentGranularity ).valueOf() );

    // current start date timestamp > current end date timestamp with end date modification calendarType
    if( calendarType === 'end' && currentStart > currentStartLimit ) 
      dateTimePickerCurrentStart.current.setDate( currentStartLimit );
  }

  const updateSelectedPeriod = ( periodType ) => 
  {
    // get dates
    const currentStart = formatDate( dateTimePickerCurrentStart.current.getDate().toLocaleDateString(), 'DD/MM/YYYY', 'YYYY-MM-DD' );
    const currentEnd = formatDate( dateTimePickerCurrentEnd.current.getDate().toLocaleDateString(), 'DD/MM/YYYY', 'YYYY-MM-DD' );
    const compareStart = formatDate( dateTimePickerCompareStart.current.getDate().toLocaleDateString(), 'DD/MM/YYYY', 'YYYY-MM-DD' );
    const compareEnd = formatDate( dateTimePickerCompareEnd.current.getDate().toLocaleDateString(), 'DD/MM/YYYY', 'YYYY-MM-DD' );

    // update preselected current period with custom dates if it matched
    if( periodType === 'current' )
    {
      // get if custom period is one of predefined period
      const predefinedPeriod = currentDataSource.filter( elem => 
        JSON.stringify( elem.date ) === JSON.stringify( { startDate: currentStart, endDate: currentEnd } ) 
      );

      // set current period
      if( predefinedPeriod.length === 1 )
        setCurrentPeriod( predefinedPeriod[0].value );
      else
        setCurrentPeriod( currentStart + '|' + currentEnd );
    }

    // update preselected compare period with custom dates if it matched
    if( periodType === 'compare' )
    {
      // get diffs 
      const startDiff = getPeriodsDiffFromDate( currentStart, compareStart );
      const endDiff = getPeriodsDiffFromDate( currentEnd, compareEnd );
      const currentDiff = getPeriodsDiffFromDate( currentStart, currentEnd );
      const compareDiff = getPeriodsDiffFromDate( compareStart, compareEnd );

      // set current period
      if( Math.abs( startDiff.yearDiff ) === 1 && Math.abs( endDiff.yearDiff ) === 1 ) setComparePeriod( 'N-1' )
      else if( currentStart === compareEnd && currentDiff.dayDiff === compareDiff.dayDiff ) setComparePeriod( 'P-1' )
      else setComparePeriod( compareStart + '|' + compareEnd );
    }
  }

  const handleChangeSelector = ( e, periodType ) => 
  {
    if( periodType === 'current' )
    {
      // predefined current values
      if( 
        e.detail.index !== currentCustomWeekIndex 
        && e.detail.index !== currentCustomMonthIndex 
      ){
        // get granularity
        const granularity = getGranularityStatus( currentDataSource[e.detail.index].value );

        // set current period
        setCurrentPeriod( currentDataSource[e.detail.index].value );
  
        // reset current custom type to null
        setCurrentGranularity( granularity );
  
      // custom current values
      } else if ( dateTimePickerCurrentStart.current !== null ) {
  
        // get granularity
        const granularity = ( e.detail.index === currentCustomWeekIndex ) ? 'week' : 'month'

        // set current period
        setCurrentPeriod(
          moment( dateTimePickerCurrentStart.current.getDate().getTime() ).startOf( granularity ).format( 'YYYY-MM-DD' )
          + '|' 
          + moment( dateTimePickerCurrentEnd.current.getDate().getTime() ).endOf( granularity ).format( 'YYYY-MM-DD' )
        )

        // set current custom type
        setCurrentGranularity( granularity );
      }

    } else if( periodType === 'compare' ) {

      // predefined compare values
      if( e.detail.index !== compareCustomIndex )
      {
        // set compare period
        setComparePeriod( compareDataSource[e.detail.index].value )

      // custom compare values
      } else if ( dateTimePickerCompareStart.current !== null ) {

        // set compare period
        setComparePeriod(            
          moment( dateTimePickerCompareStart.current.getDate().getTime() ).startOf( currentGranularity ).format( 'YYYY-MM-DD' )
          + '|' 
          + moment( dateTimePickerCompareEnd.current.getDate().getTime() ).endOf( currentGranularity ).format( 'YYYY-MM-DD' )
        )
      }
    }
  }

  const validForm = () => 
  {
    let currentParam = currentPeriod;
    let compareParam = comparePeriod;

    // update current period with current date picker if custom
    if( currentDataSource.map( elem => elem.value ).findIndex( value => value === currentPeriod ) === -1 )
      currentParam = formatDate( dateTimePickerCurrentStart.current.getDate().toLocaleDateString(), 'DD/MM/YYYY', 'YYYY-MM-DD' ) + '|' + formatDate( dateTimePickerCurrentEnd.current.getDate().toLocaleDateString(), 'DD/MM/YYYY', 'YYYY-MM-DD' )

    // update current period with current date picker if custom
    if( compareDataSource.map( elem => elem.value ).findIndex( value => value === comparePeriod ) === -1 )
      compareParam = formatDate( dateTimePickerCompareStart.current.getDate().toLocaleDateString(), 'DD/MM/YYYY', 'YYYY-MM-DD' ) + '|' + formatDate( dateTimePickerCompareEnd.current.getDate().toLocaleDateString(), 'DD/MM/YYYY', 'YYYY-MM-DD' )
    
    // update url search params
    updateURLSearchParams( { 'currentPeriod': currentParam, 'comparePeriod': compareParam }, [ searchParams, setSearchParams ] );
    
    // close window
    openCloseToggler();
  }

  const clearPeriods = () => 
  {
    // update url search params
    updateURLSearchParams( { currentPeriod: null, comparePeriod: null }, [ searchParams, setSearchParams ] );

    // close window
    openCloseToggler();
  }

  /** Update current calendars if current period change */
  useEffect( () => 
  {
    // get dates from period
    const dates = getDateFromPeriod( currentPeriod );

    // set dates to current calendar
    dateTimePickerCurrentStart.current.setDate( dates.startDate );
    dateTimePickerCurrentEnd.current.setDate( dates.endDate );

  }, [ currentPeriod ]);

  /** Update compare calendars if compare period change */
  useEffect( () => 
  {
    if( 
      currentPeriod !== null
      && comparePeriod !== null
    ){
      // get dates from period
      const dates = getDateFromPeriod( currentPeriod, comparePeriod );
  
      // set dates to compare calendar
      dateTimePickerCompareStart.current.setDate( dates.startDate );
      dateTimePickerCompareEnd.current.setDate( dates.endDate );
    }

  }, [ currentPeriod, comparePeriod ]);

  /** Update current and compare period with search params */
  useEffect( () => 
  {
    if( searchParams.get( 'currentPeriod' ) !== null )
      setCurrentPeriod( searchParams.get( 'currentPeriod' ) );
    else
      setCurrentPeriod( '12M' );

    if( searchParams.get( 'comparePeriod' ) !== null )
      setComparePeriod( searchParams.get( 'comparePeriod' ) );
    else
      setComparePeriod( 'N-1' );

  }, [ 
    searchParams.get( 'currentPeriod' ),
    searchParams.get( 'comparePeriod' )
  ]);

  return(
    <div className="calendar-selector">

      {/* Current period selector wrapper */}
      <div className="current">

        <DropDownList
          ref={ currentPeriodSelectMenu }          
          selectedIndexes={[
            currentDataSource.map( elem => elem.value ).findIndex( value => value === currentPeriod ) !== -1 ?
              currentDataSource.map( elem => elem.value ).findIndex( value => value === currentPeriod )
              : currentGranularity === 'week' ? currentCustomWeekIndex : currentCustomMonthIndex
          ]}
          placeholder='Période courante'
          dropDownHeight={ 235 }
          onChange={ e => handleChangeSelector( e, 'current' ) }
        >
          { currentDataSource.map( ( elem, index ) => <ListItem key={ index } value={ elem.value }>{ elem.label }</ListItem> ) }
        </DropDownList>

        <DateTimePicker
          ref={ dateTimePickerCurrentStart }
          className={ currentGranularity !== null ? currentGranularity : '' }
          calendarButton 
          disabled={ 
            currentDataSource.map( elem => elem.value ).findIndex( value => value === currentPeriod ) !== -1 ? 
              true 
              : false 
          }
          animation="none"
				  dropDownPosition="bottom" 
          locale="fr"
          formatString="dd/MM/yyyy"
          dropDownDisplayMode="calendar"
          firstDayOfWeek={1}
          onChange={ () => handleChangeDatePicker( 'current', 'start' ) }
        />

        <DateTimePicker
          ref={ dateTimePickerCurrentEnd }
          className={ currentGranularity !== null ? currentGranularity : '' }
          calendarButton 
          disabled={ 
            currentDataSource.map( elem => elem.value ).findIndex( value => value === currentPeriod ) !== -1 ? 
              true 
              : false 
          }
          animation="none"
				  dropDownPosition="bottom" 
          locale="fr"
          formatString="dd/MM/yyyy"
          dropDownDisplayMode="calendar"
          firstDayOfWeek={1}
          onChange={ () => handleChangeDatePicker( 'current', 'end' ) }
        />
      </div>

      {/* Compare period selector wrapper */}
      <div className="compare">

        <DropDownList
          ref={ comparePeriodSelectMenu }
          selectedIndexes={ [
            compareDataSource.map( elem => elem.value ).findIndex( value => value === comparePeriod ) !== -1 ?
              compareDataSource.map( elem => elem.value ).findIndex( value => value === comparePeriod )
              : compareCustomIndex
          ] }
          placeholder='Période de comparaison'
          dropDownHeight={ 140 }
          onChange={ e => handleChangeSelector( e, 'compare' ) }          
        >
          { compareDataSource.map( ( elem, index ) => <ListItem key={ index } value={ elem.value }>{ elem.label }</ListItem> ) }
        </DropDownList>
        
        <DateTimePicker
          ref={ dateTimePickerCompareStart }
          className={ currentGranularity !== null ? currentGranularity : '' }
          calendarButton 
          disabled={ compareDataSource.filter( elem => elem.value === comparePeriod ).length === 0  ? false : true }
          animation="none"
				  dropDownPosition="bottom" 
          locale="fr"
          formatString="dd/MM/yyyy"
          dropDownDisplayMode="calendar"
          firstDayOfWeek={1}
          onChange={ () => handleChangeDatePicker( 'compare', 'start' ) }
        />

        <DateTimePicker
          ref={ dateTimePickerCompareEnd }
          calendarButton 
          disabled={ true }
          animation="none"
				  dropDownPosition="bottom" 
          locale="fr"
          formatString="dd/MM/yyyy"
          dropDownDisplayMode="calendar"
          firstDayOfWeek={1}
          onChange={ () => handleChangeDatePicker( 'compare', 'end' ) }
        />
      </div>

      {/* Validation wrapper */}
      <div className="confirm">

        <Button 
          className='flat empty'
          onClick={ () => clearPeriods() }
        >Annuler</Button>

        <Button 
          className='flat fill' 
          onClick={ () => validForm() }        
        >Ok</Button>

      </div>
    </div>
  );
};

export default PeriodWindow;