import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {}
};

export const togglersSlice = createSlice({
  name: 'togglers',
  initialState,
  reducers: {
    onToggler: ( state, action ) => 
    {
      state.value[action.payload] = true;
    },
		offToggler: ( state, action ) => 
    {
      state.value[action.payload] = false;
    }
  }
})

export const { onToggler, offToggler } = togglersSlice.actions

export default togglersSlice.reducer