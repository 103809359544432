/**
 * Return instance datas from clientID and user datas
 * @param {String} clientID 
 * @param {Object} userDatas 
 * @returns Object or null
 */
export const getInstanceDatas = ( clientID, userDatas ) => 
{
  let instanceInfos = {};

  if( 
    userDatas?.enabled_instances !== undefined
    && userDatas.enabled_instances instanceof Array
    && userDatas.enabled_instances.length > 0
    && userDatas.enabled_instances.find( instance => instance.nickname === clientID ) !== undefined
  ){
    const instance = userDatas.enabled_instances.find( instance => instance.nickname === clientID );

    instanceInfos.label = instance.label;
    instanceInfos.devLoc = JSON.parse( instance.devLoc );
    instanceInfos.picture = instance.picture;
  }

  return instanceInfos;
}