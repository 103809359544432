/** Dependencies */
import { useState, useRef, useEffect } from 'react';
import { DropDownList, ListItem } from 'smart-webcomponents-react/dropdownlist';
import { Slider } from 'smart-webcomponents-react/slider';

/** Helpers */
import { deepEqual } from './../../helpers/functions';

/** SCSS */
import './FilterSliderDropDownMenu.scss';

function FilterSliderDropDownMenu( props )
{
  const {
    id,
    sliderMinValue,
    sliderMaxValue,
    sliderInterval,
    dropDownMenuValues,
    initCurrentValues,
    callBackFunction
  } = props;

  /** Define refs */
  const sliderRef = useRef();
  const dropDownListPredefinedRef = useRef();

  /** Init State */
  const [ currentValues, setCurrentValues ] = useState( null );

  const handleHorizontalSliderChange = e => 
  {
    // get range values
    const rangeValues = e.detail.value.map( elem => parseInt( elem ) );

    // set current slider values
    if( !deepEqual( rangeValues, currentValues ) )
      setCurrentValues( rangeValues );
  }

  const handleDropDownMenuChange = e => 
  {
    // get value
    const value = e.detail.value !== '' ? e.detail.value : null;

    if( value !== null )
    {
      // get range values
      let rangeValues = value.split( '|' ).map( elem => parseInt( elem ) );

      // set current slider values
      if( !deepEqual( rangeValues, currentValues ) )
        setCurrentValues( rangeValues );
    }
  }

  useEffect( () => 
  {
    if(
      currentValues !== null 
      && typeof callBackFunction === 'function' 
      && !deepEqual( currentValues, initCurrentValues )
    )
      callBackFunction( currentValues );

  }, [ currentValues ]);

  return(
    <div className="slider-dropdown-menu">

      {/* Value slider */}
      <Slider 
        ref={ sliderRef }
        id={ id + '-slider' }
        min={ sliderMinValue }
        max={ sliderMaxValue }
        labelFormatFunction={ value => 
          id === 'filter-range-position-slider-dropdown-menu' ? 
            value === 101 ? 
              'NP' 
              : Intl.NumberFormat( 'fr-FR', { notation: 'compact' } ).format( value )
            : Intl.NumberFormat( 'fr-FR', { notation: 'compact' } ).format( value ) 
        }
        interval={ sliderInterval }
        showTooltip
        coerce 
        scalePosition="far" 
        scaleType="integer"
        rangeSlider 
        values={ 
          currentValues !== null && Array.isArray( currentValues ) ? 
            [ ...currentValues ]
            : initCurrentValues !== null && Array.isArray( initCurrentValues ) ?
              [ ...initCurrentValues ]
              : [ sliderMinValue, sliderMaxValue ]
        }
        onChange={ e => handleHorizontalSliderChange( e ) }
      ></Slider>

      {/* Drop down list Tranches */}
      <DropDownList
        ref={ dropDownListPredefinedRef }
        dataSource={ dropDownMenuValues }
        selectedValues={ 
          currentValues !== null && Array.isArray( currentValues ) ? 
          [ currentValues.join( '|' ) ] 
            : initCurrentValues !== null && Array.isArray( initCurrentValues ) ?
              [ initCurrentValues.join( '|' ) ]
              : [] 
        }
        placeholder='Tranche'
        onChange={ e => handleDropDownMenuChange( e ) }
      >
        { dropDownMenuValues.map( ( elem, index ) => <ListItem key={ index } value={ elem.value }>{ elem.label }</ListItem> ) }
      </DropDownList>

    </div>
  )
}

export default FilterSliderDropDownMenu;