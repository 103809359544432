/** Dependencies */
import { createElement, useState, useEffect, useRef } from 'react';
import { renderToString } from 'react-dom/server';
import DropDownList from 'smart-webcomponents-react/dropdownlist';

/** Helpers */
import { deepEqual } from './../../helpers/functions';
import { getPicto } from './../../helpers/pictos';

function DropDownMenuMulti( props )
{
  const {
    id,
    label,
    dropDownMenuValues,
    initCurrentValues,
    callBackFunction
  } = props;

  /** Refs */
  const dropDownListRef = useRef();

  /** Init State */
  const [ currentValues, setCurrentValues ] = useState( null );
  const [ selectedValues, setSelectedValues ] = useState( null );

  const handleDropDownMenuChange = e => 
  {
    // update selected values
    setSelectedValues( { selectedValues: dropDownListRef.current.selectedValues } );

    // clone current values
    let currentValuesClone = structuredClone( currentValues === null ? [] : currentValues );

    // add items 
    if( e.detail.addedItems.length > 0 )
    {
      let addedItems = [];
      e.detail.addedItems.forEach( item => 
      {
        if( !currentValuesClone.map( elem => elem.value ).includes( item.value ) )
          addedItems = [ ...addedItems, { label: item.label, value: item.value } ];
      });

      if( addedItems.length > 0 ) 
        currentValuesClone = [ ...currentValuesClone, ...addedItems ];
    }

    // remove items 
    if( e.detail.removedItems.length > 0 )
    {
      let removedItems = [];
      e.detail.removedItems.forEach( item => 
      {
        removedItems = [ ...removedItems, item.value ];
      });

      if( removedItems.length > 0 ) 
        currentValuesClone = currentValuesClone.filter( elem => !removedItems.includes( elem.value ) );
    }

    // set current values
    setCurrentValues( currentValuesClone );

    // update tokens with pictos
    Array.from( dropDownListRef.current.nativeElement.getElementsByClassName( 'smart-token' ) ).forEach( token => 
    {
      // get token content
      const tokenContent = token.innerHTML;

      // get current label
      const label = token.getElementsByClassName( 'smart-drop-down-list-selection-label' )[0].textContent;

      // get value from label
      const value = dropDownMenuValues.filter( item => item.label === label )[0].value;

      // create span element with picto
      const picture = createElement( 
        'span', { className: 'smart-drop-down-list-selection-picture' }, 
        getPicto( value, { size: '1rem' } )
      );

      // replace token content
      token.innerHTML = renderToString( picture ) + tokenContent;
    });
  }

  useEffect( () => 
  {
    // send current values to parent
    if(
      currentValues !== null 
      && typeof callBackFunction === 'function' 
      && !deepEqual( currentValues, initCurrentValues )
    )
      callBackFunction( currentValues );

  }, [ JSON.stringify( currentValues ) ]);

  useEffect( () => 
  {
    if( initCurrentValues !== null && currentValues === null )
      setSelectedValues( { selectedValues: initCurrentValues.map( elem => elem.value ) } );

  }, [ JSON.stringify( initCurrentValues ) ]);

  return(
    <DropDownList 
      ref={ dropDownListRef }
      id={ id }
      dataSource={ dropDownMenuValues }
      placeholder={ label } 
      filterable
      selectionDisplayMode="tokens"
      selectionMode="zeroOrMany"
      dropDownMinHeight={ 150 }
      { ...selectedValues }
      onChange={ e => handleDropDownMenuChange( e ) }        
    ></DropDownList>
  );
}

export default DropDownMenuMulti;