/** Dependencies */
import moment from 'moment';

/** Helpers */
import { 
  getMostRecentFromArrayObject,
  getSumFromArrayOfObject, 
  getAvgFromArrayOfObject, 
  getTopXFromArrayObject,
  sortArrayByObjectField
} from './functions';
import { formatDate } from './dates';
import { getPicto } from './pictos';

// Define increase and decrease colors
const blueColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-blue' ).trim();

/**
 * Return Array with values formatted for Chart component
 * @param {Array} currentDatas 
 * @param {Array} compareDatas 
 * @param {String} periodType 
 * @returns Array
 */
export const formatDatasForChart = ( currentDatas, compareDatas, periodType = 'month' ) => 
{
  let datas = [];

  // format datas
  if( 
    Array.isArray( currentDatas ) && currentDatas.length > 0
    && Array.isArray( compareDatas ) && compareDatas.length > 0
  ){
    // refill datas if different length between current and compare
    const { refillCurrentDatas, refillCompareDatas } = refillDatas( currentDatas, compareDatas, periodType );

    // append values on datas array
    if( refillCurrentDatas.length === refillCompareDatas.length )
      refillCurrentDatas.forEach( ( values, index ) => 
      {
        datas = [...datas, { 
          xaxis: periodType === 'month' ?
            formatDate( values.x, 'YYYYMM', 'MMM' ) 
            : formatDate( values.x, 'YYYYMMDD', 'DD/MM/YY' ), 
          current: values.y, 
          compareXaxisCaption: periodType === 'month' ?
            formatDate( refillCompareDatas[index].x, 'YYYYMM', 'MMM' ) 
            : formatDate( refillCompareDatas[index].x, 'YYYYMMDD', 'DD/MM/YY' ), 
          compare: refillCompareDatas[index].y 
        }];      
      });

  // Object case
  } else if(
    !Array.isArray( currentDatas )
    && currentDatas instanceof Object
    && Object.keys( currentDatas ).length > 0
  ) {
    // get all dates of period from first key value
    const period = currentDatas[Object.keys( currentDatas )[0]].map( data => data.x );
    
    // get values for each period
    period.forEach( ( date, index ) => 
    {
      // format date to xaxis
      let values = { 
        xaxis: periodType === 'month' ?
          formatDate( date, 'YYYYMM', 'MMM' ) 
          : formatDate( date, 'YYYYMMDD', 'DD/MM/YY' ), 
      };

      // get values for each top range
      Object.keys( currentDatas ).forEach( key =>
      {
        values[key] = currentDatas[key][index].y
      });

      // append values on datas array
      datas = [...datas, values];        
    });
  }

  return datas;
}

/**
 * Return Array with null values in current or compared array to be equal with entries
 * @param {Array} currentDatas 
 * @param {Array} compareDatas 
 * @param {String} periodType 
 * @returns Array
 */
const refillDatas = ( currentDatas, compareDatas, periodType ) => 
{
  // clone datas arrays
  const refillCurrentDatas = [ ...currentDatas ];
  const refillCompareDatas = [ ...compareDatas ];

  // function to add month or week value
  const increaseDate = ( date, periodType ) => periodType === 'month' ? 
    parseInt( moment( date, 'YYYYMMDD' ).add( 1, 'months' ).format( 'YYYYMMDD' ) ) 
    : parseInt( moment( date, 'YYYYMMDD' ).add( 1, 'weeks' ).format( 'YYYYMMDD' ) );

  if( 
    Array.isArray( currentDatas ) 
    && Array.isArray( compareDatas )
    && currentDatas.length > 0
    && compareDatas.length > 0
    && currentDatas.length !== compareDatas.length
  ){
    // get diff length between current and compare datas array
    const diffLenght = currentDatas.length - compareDatas.length;

    // get last date value 
    let lastDate = ( diffLenght > 0 ) ? compareDatas.slice( -1 )[0].x : currentDatas.slice( -1 )[0].x;

    // add missed dates
    for ( let i = 0 ; i < Math.abs( diffLenght ); i++ )
    {
      // get last date
      lastDate  = increaseDate( lastDate, periodType );

      // push value if current datas array longest
      if( diffLenght > 0 )
        refillCompareDatas.push( { x: lastDate, y: null } );
      
      // push value if compare datas array longest
      else
        refillCurrentDatas.push( { x: lastDate, y: null } );
    }    
  }

  return { refillCurrentDatas: refillCurrentDatas, refillCompareDatas: refillCompareDatas };
}

/**
 * Return data object for InsightCompare component after getting last value
 * @param {Array} currentDatas 
 * @param {Array} compareDatas 
 * @param {Boolean} flip 
 * @returns Array
 */
export const aggregateDatasForInsightCompareMostRecent = ( currentDatas, compareDatas, flip = false ) =>
{
  return formatDatasforInsightCompare(
    { 
      currentValues: getMostRecentFromArrayObject( currentDatas, 'x', 'y', true ), 
      compareValues: getMostRecentFromArrayObject( compareDatas, 'x', 'y', true )
    },
    flip
  );
}

/**
 * Return data object for InsightCompare component after sum processing
 * @param {Array} currentDatas 
 * @param {Array} compareDatas 
 * @param {Boolean} flip 
 * @returns Array
 */
export const aggregateDatasForInsightCompareSum = ( currentDatas, compareDatas, flip = false ) =>
{
  return formatDatasforInsightCompare(
    { 
      currentValues: getSumFromArrayOfObject( currentDatas, 'y' ), 
      compareValues: getSumFromArrayOfObject( compareDatas, 'y' )    
    },
    flip
  );
}

/**
 * Return data object for InsightCompare component after avg processing
 * @param {Array} currentDatas 
 * @param {Array} compareDatas 
 * @param {Boolean} flip 
 * @returns Array
 */
export const aggregateDatasForInsightCompareAvg = ( currentDatas, compareDatas, flip = false ) =>
{
  return formatDatasforInsightCompare(
    { 
      currentValues: getAvgFromArrayOfObject( currentDatas, 'y' ), 
      compareValues: getAvgFromArrayOfObject( compareDatas, 'y' ) 
    }, 
    flip
  );
}

/**
 * Return datas object with current and compare values 
 * after adding insights values to display InsightCompare component
 * @param {Object} datas 
 * @param {Boolean} flip 
 * @returns Object
 */
const formatDatasforInsightCompare = ( datas, flip = false ) =>
{
  return datas.insight = {
    current: datas.currentValues,
    compare: datas.compareValues,
    variation: (
      datas.compareValues !== null && datas.currentValues !== null
      ) ? 
      flip ? 
        datas.compareValues - datas.currentValues 
        : datas.currentValues - datas.compareValues
      : null
  }
}

/**
 * Return Array with value / classname with predefined colors
 * from data object for grp snippets list
 * @param {Object} currentDatas 
 * @returns Array
 */
export const aggregateDatasForInsightGrpSnippetsList = ( currentDatas ) => 
{
  // get values
  const values = Object.keys( currentDatas ).map( key => 
  {
    // get class 
    let className = null;
    switch ( key ) {
      case 'Ads': className = 'orange'; break;
      case 'Snippets': className = 'yellow'; break;
      case 'SEO': className = 'blue'; break;    
      default:
        break;
    }

    return {
      label: key,
      value: getMostRecentFromArrayObject( currentDatas[key], 'x', 'y', true ),
      className: className
    }
  });

  // return with order
  return [ 
    values.filter( elem => elem.label === 'SEO' )[0],
    values.filter( elem => elem.label === 'Ads' )[0], 
    values.filter( elem => elem.label === 'Snippets' )[0]    
  ];
}

/**
 * Call aggregated function with sum
 * @param {Object} currentDatas 
 * @returns Array
 */
export const aggregateDatasForInsightTypeSnippetsListSum = currentDatas => 
  aggregateDatasForInsightTypeSnippetsList( currentDatas, 'sum' );

/**
 * Call aggregated function with avg
 * @param {Object} currentDatas 
 * @returns Array
 */
export const aggregateDatasForInsightTypeSnippetsListAvg = currentDatas => 
  aggregateDatasForInsightTypeSnippetsList( currentDatas, 'avg' );

/**
 * Call aggregated function with most recently
 * @param {Object} currentDatas 
 * @returns Array
 */
export const aggregateDatasForInsightTypeSnippetsListMostRecent = currentDatas => 
  aggregateDatasForInsightTypeSnippetsList( currentDatas, 'mostrecent' );

/**
 * Return Array with value / classname with gradient / picto 
 * from datas object for type snippets list
 * @param {Object} currentDatas 
 * @returns Array
 */
export const aggregateDatasForInsightTypeSnippetsList = ( currentDatas, aggregateType ) => 
{
  // get values
  let values = Object.keys( currentDatas ).map( key => 
  {
    return {
      label: key,
      value: 
        aggregateType === 'avg' ? 
          getAvgFromArrayOfObject( currentDatas[key], 'y' )
        : aggregateType === 'sum' ? 
          getSumFromArrayOfObject( currentDatas[key], 'y' )
        : 
          getMostRecentFromArrayObject( currentDatas[key], 'x', 'y', true )
    }
  })
  
  // sort values
  values = sortArrayByObjectField( values, 'value', 'number', 'desc' );
  
  // add className by order AND picto by label
  values.forEach( ( value, index ) => 
  {
    // get className
    let className = '';
    switch ( index ) {
      case 0: className = 'blue opacity-075'; break;
      case 1: className = 'blue opacity-06'; break;
      case 2: className = 'blue opacity-045'; break;
      case 3: className = 'blue opacity-03'; break;
      case 4: className = 'blue opacity-015'; break;    
      default:
        break;
    }

    // set className
    value.className = className

    // set picto
    value.picto = getPicto( value.label, { size: '1rem', weight: 'bold' } );
  });

  return values;
}

/**
 * Return series for Chart component with gradient color for Top 5
 * @param {Array} datas 
 * @returns Array
 */
export const createCustomSeriesOrderByValues = ( datas ) => 
{
  let series = [];

  if( Array.isArray( datas ) )
  {
    let cloneDatas = structuredClone( datas );
  
    // remove xaxis name if exist
    cloneDatas.forEach( elem => elem?.xaxis !== undefined ? delete elem.xaxis : elem );

    // get sum of each value
    const topValues = getTopXFromArrayObject( cloneDatas, 5 );
  
    // set series with top values
    series = topValues.map( ( value, index ) => ({ 
      dataField: value.key,
      opacity: ( 0.75 - ( index * 0.15 ) ).toFixed( 2 ),
      fillColor: blueColor,
      fillColorSymbolSelected: blueColor,
      lineColor: blueColor,
      lineWidth: 0
    })); 
  }

  return series;
}

/**
 * Return array of predefined ranges values for dropdown menu in filters
 * @param {Array} ranges [ '0|10', '10|100', '100|1000', '1000|10000', '10000|100000', '100000|more' ...] 
 * @param {Integer} maxValue 
 * @returns 
 */
export const getPredefinedFilterRangesValues = ( ranges, minValue, maxValue ) => 
{
  let predefinedValues = [];

  // format min value
  const minValueFormat = Intl.NumberFormat( 'fr-FR', { notation: 'compact', roundingMode: 'ceil' } ).format( minValue );

  // format max value
  const maxValueFormat = Intl.NumberFormat( 'fr-FR', { notation: 'compact', roundingMode: 'ceil' } ).format( maxValue );

  ranges.forEach( range => 
  { 
    // split range value
    const minRangeValue = range.split( '|' )[0];
    const minRangeValueFormat = Intl.NumberFormat( 'fr-FR', { notation: 'compact', roundingMode: 'ceil' } ).format( range.split( '|' )[0] );
    const maxRangeValue = range.split( '|' )[1];
    const maxRangeValueFormat = Intl.NumberFormat( 'fr-FR', { notation: 'compact', roundingMode: 'ceil' } ).format( range.split( '|' )[1] );

    if( minValue <= maxRangeValue && maxValue >= minRangeValue && maxRangeValue !== 'more' )
      predefinedValues.push( { 
        label: ( minValue > minRangeValue ? minValueFormat : minRangeValueFormat ) + ' - ' + ( maxValue < maxRangeValue ? maxValueFormat : maxRangeValueFormat ), 
        value: ( minValue > minRangeValue ? minValue : minRangeValue ) + '|' + ( maxValue < maxRangeValue ? maxValue : maxRangeValue )
      });
    else if( maxValue > minRangeValue && maxRangeValue === 'more' )
      predefinedValues.push( { 
        label: minRangeValueFormat + '+', 
        value: minRangeValue + '|' + maxValue
      });
  });

  return predefinedValues;
}