/** Dependencies */
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

/** Reducers */
import { onToggler } from './../../../reducers/togglers';

/** Helpers */
import { getPicto } from './../../../helpers/pictos';

/** SCSS */
import './MainMenu.scss';

function MainMenu()
{
  /** Instance dispatch object **/
	const dispatch = useDispatch();

  /** Instance useNavigate */
  const location = useLocation();

  /** Get state from redux store **/
  const userDatas = useSelector( state => state.userDatas.value );

  // get navigation path
  const pathname = location.pathname;

  // get first instance for dashboard link
  const devLoc = JSON.parse( userDatas.enabled_instances[0].devLoc )[0];
  const firstInstance = userDatas.enabled_instances[0].nickname + '/' + devLoc.countryValue + '/' + devLoc.deviceValue;

  return(
    <nav>
      <div id="main-menu" className="main-menu">
        <ul>
          <li>
            <Link 
              to="#"
              onClick={ () => dispatch( onToggler( 'burgerMenuIsOpened' ) ) }
            >{ getPicto( 'List', { size: 25, color: 'white' } ) }</Link>
          </li>
          <li>
            <Link 
              className={ pathname.includes( '/dashboard/' ) ? 'active' : '' } 
              to={ 'dashboard/' + firstInstance }
            >{ getPicto( 'Pulse', { size: 25, color: 'white' } ) }</Link>
          </li>
        </ul>
      </div>
    </nav>
  )
} 

export default MainMenu;