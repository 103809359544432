/** Dependencies */
import { useEffect, useState } from 'react';
import { ButtonGroup } from 'smart-webcomponents-react/buttongroup';
import { useSearchParams } from "react-router-dom";

/** Helpers */
import { updateURLSearchParams } from './../../helpers/functions';
import { getPicto } from './../../helpers/pictos';

/** SCSS */
import './PeriodSelector.scss';

function PeriodSelector()
{
  /** Set Datas source for select menu */
  const currentDataSource = [
    { label: "12M", value: "12M" },
    { label: "6M", value: "6M" },
    { label: "3M", value: "3M" },
    { label: "12S", value: "12S" },
    { label: "4S", value: "4S" }
  ];

  const compareDataSource = [
    { label: getPicto( 'CalendarPMinus1', {}, true ), value: "P-1" },
    { label: getPicto( 'CalendarNMinus1', {}, true ), value: "N-1" }
  ]

  /** Instance useSearchParams Hook */
  let [ searchParams, setSearchParams ] = useSearchParams();

  /** Init State */
  const [ currentPeriod, setCurrentPeriod ] = useState( null );
  const [ comparePeriod, setComparePeriod ] = useState( null );

  /** Set current and compare period from searchParams */
  useEffect( () => 
  {
    if( searchParams.get( 'currentPeriod' ) !== null )
      setCurrentPeriod( searchParams.get( 'currentPeriod' ) );
    else
      setCurrentPeriod( '12M' );

    if( searchParams.get( 'comparePeriod' ) !== null )
      setComparePeriod( searchParams.get( 'comparePeriod' ) );
    else
      setComparePeriod( 'N-1' );

  }, [ 
    searchParams.get( 'currentPeriod' ),
    searchParams.get( 'comparePeriod' ) 
  ]);

  return(
    <div className='period-selector'>

      { currentPeriod !== null ?
        <ButtonGroup
          dataSource={ currentDataSource }
          selectionMode='zeroOrOne'
          selectedValues={ 
            currentDataSource.filter( elem => elem.value === currentPeriod ).length === 1 ?
              [ currentPeriod ]
              : []
          }
          onChange={ e => updateURLSearchParams({ 
            currentPeriod: ( e.detail.values[0] !== undefined ? e.detail.values[0] : e.detail.oldValue[0] ),
            comparePeriod: comparePeriod
          }, [ searchParams, setSearchParams ])}
        />
        : null
      }
      <ButtonGroup
        dataSource={ compareDataSource }
        selectionMode='zeroOrOne'
        selectedValues={ 
          compareDataSource.filter( elem => elem.value === comparePeriod ).length === 1 ?
            [ comparePeriod ]
            : []
        }
        onChange={ e => updateURLSearchParams({ 
          currentPeriod: currentPeriod,
          comparePeriod: ( e.detail.values[0] !== undefined ? e.detail.values[0] : e.detail.oldValue[0] )
        }, [ searchParams, setSearchParams ])}
      />
    </div>
  )
}

export default PeriodSelector;  