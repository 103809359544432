/** Dependencies */
import React, { Fragment } from 'react';
import { createRoot } from 'react-dom/client';

/** Helpers */
import { getPicto } from '../../../helpers/pictos';

/** SCSS */
import './GridCellTextLink.scss';

function GridCellTextLink( props )
{
  const {
    value,
    link
  } = props;

  // create html element wrapper
  const rootElement = document.createElement( 'div' );

  // add class
  rootElement.className = 'textlink-wrapper';

  // create root element to append new div element
  const root = createRoot( rootElement );

  // render element
  root.render(
    <Fragment>
      { value }
      
      { link ?
        <a target="_blank" rel="noreferrer" href={ link }>{ getPicto( 'GoogleLink', { width: 24, height: 24 } ) }</a>
        : null
      }
    </Fragment>
  );

  return rootElement;
} 

export default GridCellTextLink;