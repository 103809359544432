/** Dependencies */
import { useSelector, useDispatch } from 'react-redux';

/** Reducers */
import { onToggler } from './../../reducers/togglers';

/** Helpers */
import { getPicto } from "./../../helpers/pictos";

function FilterRemoverAll( props )
{
  const{ 
    callBackFunction
  } = props;

  /** Instance dispatch object **/
	const dispatch = useDispatch();
  
  /** Get state from redux store **/
  const togglers = useSelector( state => state.togglers.value );

  // Define increase and decrease colors
  const grey2Color = getComputedStyle( document.documentElement ).getPropertyValue('--color-grey2').trim();

  return(
    <div 
      className="filter picto"
      id={ 'filter-all' }
    >
      <div 
        className="label"
        onClick={ !togglers[ 'filters-windowbutton' ] ? () => dispatch( onToggler( 'filters-windowbutton' ) ) : null }
      >Tous</div>
      <button 
        onClick={ () => callBackFunction() }
      >{ getPicto( 'X', { size: '1rem', fill: grey2Color, weight: 'bold' } ) }</button>
    </div>
  )
}

export default FilterRemoverAll;