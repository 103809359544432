/** Dependencies */
import { useSelector, useDispatch } from 'react-redux';

/** Reducers */
import { onToggler } from './../../reducers/togglers';

/** Helpers */
import { getPicto } from "./../../helpers/pictos";

/** JSON */
import i18n from './../../assets/json/i18n.json';

function FilterRemoverPicto( props )
{
  const{ 
    label,
    field,
    value,
    filterName,
    callBackFunction
  } = props;

  /** Instance dispatch object **/
	const dispatch = useDispatch();
  
  /** Get state from redux store **/
  const togglers = useSelector( state => state.togglers.value );

  // Define increase and decrease colors
  const grey2Color = getComputedStyle( document.documentElement ).getPropertyValue('--color-grey2').trim();
  const orangeColor = getComputedStyle( document.documentElement ).getPropertyValue('--color-orange').trim();
  const yellowColor = getComputedStyle( document.documentElement ).getPropertyValue('--color-yellow').trim();
  const blueColor = getComputedStyle( document.documentElement ).getPropertyValue('--color-blue').trim();

  // Define picto with value
  let picto = null;
  switch ( value ) {
    case 'increase': picto = { name: 'ArrowUpRight', options:{ size : '1rem', fill: blueColor } }; break;
    case 'constant': picto = { name: 'ArrowRight', options:{ size : '1rem', fill: yellowColor } }; break;
    case 'decrease': picto = { name: 'ArrowDownRight', options:{ size : '1rem', fill: orangeColor } }; break;
    case 'in': picto = { name: 'SignIn', options:{ size : '1rem', fill: blueColor } }; break;
    case 'out': picto = { name: 'SignOut', options:{ size : '1rem', fill: orangeColor } }; break;
    default: picto = null; break;
  }

  return(
    <div 
      className="filter picto"
      id={ 'filter-' + filterName + '-' + field }
    >
      <div 
        className="label" 
        title={ label + ' : ' + i18n.fr.filters[value] }
        onClick={ !togglers[ 'filters-windowbutton' ] ? () => dispatch( onToggler( 'filters-windowbutton' ) ) : null }
      >
        { label }&nbsp;
        { 
          picto !== null ?
            getPicto( picto.name, picto.options ) 
            : null
        }
      </div>
      <button 
        onClick={ () => callBackFunction( filterName, field ) }
      >{ getPicto( 'X', { size: '1rem', fill: grey2Color, weight: 'bold' } ) }</button>
    </div>
  )
}

export default FilterRemoverPicto;