import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: null
}

export const userDatasSlice = createSlice({
  name: 'userDatas',
  initialState,
  reducers: {
    updateUserDatas: ( state, action ) => 
    {
      state.value = {...state.value, ...action.payload};
    }
  }
})

export const { updateUserDatas } = userDatasSlice.actions

export default userDatasSlice.reducer