/** Dependencies */
import React, { Fragment } from 'react';

/** Components */
import InsightsCompareValues from './InsightsCompareValues';

/** SCSS */
import './InsightsListValues.scss';

function InsightsListValues( props )
{
  const {
    values,
    unit
  } = props;

  return(
    <Fragment>
    {
      <ul className='insights-list'>{ values.filter( elem => elem !== undefined ).map( ( value, index ) => 
        value?.label !== undefined ?
          <li 
            key={ index }
            className={ value?.className !== undefined ? value.className : '' }
          >
            <div className='picto-label'>
              { value?.picto ? value.picto : null }<span className='label' title={ value.label }>{ value.label }</span>
            </div>
            <InsightsCompareValues currentValue = { value.value } unit={ unit } notation={ 'compact' } />
          </li>
          : null
      )}</ul>
    }
    </Fragment>
  )
}

export default InsightsListValues;