/** Dependencies */
import { useSelector, useDispatch } from 'react-redux';

/** Reducers */
import { onToggler } from './../../reducers/togglers';

/** Helpers */
import { getPicto } from "./../../helpers/pictos";

function FilterRemoverPlainText( props )
{
  const{ 
    label,
    field,
    value,
    filterName,
    callBackFunction
  } = props;

  /** Instance dispatch object **/
	const dispatch = useDispatch();
  
  /** Get state from redux store **/
  const togglers = useSelector( state => state.togglers.value );

  // Define increase and decrease colors
  const grey2Color = getComputedStyle( document.documentElement ).getPropertyValue('--color-grey2').trim();

  // get value
  let currentValue = value
  if( value?.value )
    currentValue = value.value

  return(
    <div 
      className="filter plaintext" 
      id={ 'filter-' + filterName + '-' + field }
    >
      <div 
        className="label" 
        title={ label + ' : ' + currentValue }
        onClick={ !togglers[ 'filters-windowbutton' ] ? () => dispatch( onToggler( 'filters-windowbutton' ) ) : null }
      >{ label + ' : ' + currentValue }</div>
      <button 
        onClick={ () => callBackFunction( filterName, field ) }
      >{ getPicto( 'X', { size: '1rem', fill: grey2Color, weight: 'bold' } ) }</button>
    </div>
  )
}

export default FilterRemoverPlainText;