/** Dependencies */
import moment from 'moment';
import 'moment/locale/fr';

/**
 * Return object with start date and end date from period
 * @param {String} period 
 * @param {String} delay
 * @returns Object
 */
export const getDateFromPeriod = ( period, delay = null, format = 'YYYY-MM-DD' ) => 
{
  // define default date
  let date = {
    startDate: moment(),
    endDate: moment()
  };

  // define period type and period length
  let periodType = null;
  let periodLength = null;

  // get dates from period
  if( typeof period === 'string' )
  {
    // match period with regex
    const predefinedPeriodMatches = period.match( /^([0-9]{1,2})([MS]{1})$/ );
    const customPeriodMatches = period.match( /^([0-9]{4}-[0-9]{2}-[0-9]{2})\|([0-9]{4}-[0-9]{2}-[0-9]{2})$/ );

    if( predefinedPeriodMatches !== null )
    {
      periodLength = predefinedPeriodMatches[1];
      periodType = predefinedPeriodMatches[2];
    
      // substract value to get start date of period
      date.startDate = date.startDate.subtract( periodLength - 1, periodType === 'S' ? 'weeks' : 'months' );      
  
      // get start and end of period
      date.startDate = date.startDate.startOf( periodType === 'S' ? 'weeks' : 'months' ).startOf( 'day' );
      date.endDate = date.endDate.endOf( periodType === 'S' ? 'weeks' : 'months' ).startOf( 'day' );

    } else if( customPeriodMatches !== null ) {

      periodLength = getPeriodsDiffFromDate( customPeriodMatches[1], moment( customPeriodMatches[2] ).add( 1, 'day' ) );
      periodType = ( Number.isInteger( periodLength.monthDiff ) ) ? 'M' : 'S';

      // get start and end of period
      date.startDate = moment( customPeriodMatches[1] ).startOf( 'day' );
      date.endDate = moment( customPeriodMatches[2] ).startOf( 'day' );
    }
  }

  // apply delay if exist
  if( delay !== null && typeof delay === 'string' )
  { 
    // match period with regex
    const predefinedDelayTypeMatches = delay.match( /^([N|P]{1,2})-1$/ );
    const customDelayTypeMatches = delay.match( /^([0-9]{4}-[0-9]{2}-[0-9]{2})\|([0-9]{4}-[0-9]{2}-[0-9]{2})$/ );

    // predefined delay type
    if( predefinedDelayTypeMatches !== null )
    {
      // delay type year - 1
      if( predefinedDelayTypeMatches[1] === 'N' )
      {
        // use week number if period type is week
        if( periodType === 'S' )
        {
          // get week number
          const startWeekNumber = date.startDate.week();
          const endWeekNumber = date.endDate.week();

          date.startDate = date.startDate.subtract( 1, 'years' ).week( startWeekNumber ).startOf( 'week' );
          date.endDate = date.endDate.subtract( 1, 'years' ).week( endWeekNumber ).endOf( 'week' );

        } else {
          date.startDate = date.startDate.subtract( 1, 'years' );
          date.endDate = date.endDate.subtract( 1, 'years' );
        }

  
      // delay type period - 1
      } else if( predefinedDelayTypeMatches[1] === 'P' ) {
  
        // get period length
        const periodLength = getPeriodsDiffFromDate( date.startDate, date.endDate.add( 1, 'day' ) );
  
        if( Number.isInteger( periodLength.monthDiff ) )
        {        
          date.startDate = date.startDate.subtract( periodLength.monthDiff, 'months' );
          date.endDate = date.endDate.subtract( periodLength.monthDiff, 'months' ).subtract( 1, 'day' );
        } else {
          date.startDate = date.startDate.subtract( periodLength.dayDiff, 'days' );
          date.endDate = date.endDate.subtract( periodLength.dayDiff, 'days' ).subtract( 1, 'day' );
        }
      }
    
    // custom delay type
    } else if( customDelayTypeMatches !== null ) {

      // get period length
      const periodLength = getPeriodsDiffFromDate( date.startDate, date.endDate.add( 1, 'day' ) );

      if( Number.isInteger( periodLength.monthDiff ) )
      {        
        date.startDate = moment( customDelayTypeMatches[1] ).startOf( 'months' );
        date.endDate = moment( customDelayTypeMatches[1] ).subtract( 1, 'day' ).add( periodLength.monthDiff, 'months' ).endOf( 'months' );
      } else {
        date.startDate = moment( customDelayTypeMatches[1] ).startOf( 'week' );
        date.endDate = moment( customDelayTypeMatches[1] ).subtract( 1, 'day' ).add( periodLength.dayDiff, 'day' ).endOf( 'week' );
      }
    }
  }
  
  // format date to string
  date.startDate = date.startDate.format( format );
  date.endDate = date.endDate.format( format );

  return date;
}

export const getPeriodsDiffFromDate = ( startDate, endDate ) => 
{
  if( !moment.isMoment( startDate ) )
    startDate = moment( startDate )

  if( !moment.isMoment( endDate ) )
    endDate = moment( endDate )

  // get difference by year
  const yearDiff = endDate.clone().startOf( 'day' ).diff( startDate.clone(), 'year', true );

  // get difference by month
  const monthDiff = endDate.clone().startOf( 'day' ).diff( startDate.clone(), 'month', true );

  // get difference by weeks
  const weekDiff = endDate.clone().startOf( 'day' ).diff( startDate.clone(), 'week', true );

  // get difference by days
  const dayDiff = endDate.clone().startOf( 'day' ).diff( startDate.clone(), 'day', true );

  return {
    yearDiff: yearDiff,
    monthDiff: monthDiff,
    weekDiff: weekDiff,
    dayDiff: dayDiff
  };
}

export const getPeriodGranularity = period => 
{
  // set return
  let periodGranularity = [];

  const getMonths = ( startDate, duration ) => 
  {
    for( let i = 0; i < duration; i++ )
      periodGranularity.push( moment( startDate ).startOf( 'month' ).add( i, 'M' ).format( 'YYYY MMM' ) );
  }

  const getWeeks = ( startDate, duration ) => 
  {
    for( let i = 0; i < duration; i++ )
      periodGranularity.push( 
        moment( startDate ).startOf( 'week' ).add( i, 'weeks' ).format( 'DD/MM' ) 
        + '|'
        + moment( startDate ).startOf( 'week' ).add( i, 'weeks' ).add( 6, 'days' ).format( 'DD/MM' ) 
      );
  }

  if( typeof period === 'string' )
  {
    // get dates
    const periodDates = getDateFromPeriod( period );

    // match period with regex
    const predefinedPeriodMatches = period.match( /^([0-9]{1,2})([MS]{1})$/ );
    const customPeriodMatches = period.match( /^([0-9]{4}-[0-9]{2}-[0-9]{2})\|([0-9]{4}-[0-9]{2}-[0-9]{2})$/ );
  
    if( predefinedPeriodMatches !== null ) 
    {
      // months case
      if( predefinedPeriodMatches[2] === 'M' )
        getMonths( periodDates.startDate, predefinedPeriodMatches[1] );

      // weeks case
      else if( predefinedPeriodMatches[2] === 'S' )
        getWeeks( periodDates.startDate, predefinedPeriodMatches[1] );
      
    } else if( customPeriodMatches !== null ) {

      // get diff between start and end date
      const { weekDiff } = getPeriodsDiffFromDate( 
        moment( periodDates.startDate ).startOf( 'week' ), 
        moment( periodDates.endDate ).endOf( 'week' ) 
      );

      const { monthDiff } = getPeriodsDiffFromDate( 
        moment( periodDates.startDate ).startOf( 'month' ), 
        moment( periodDates.endDate ).endOf( 'month' ) 
      );
            
      // month case
      if( weekDiff > 12 )
        getMonths( periodDates.startDate, Math.ceil( monthDiff ) );

      // weeks case
      else
        getWeeks( periodDates.startDate, Math.ceil( weekDiff ) );
    }    
  }

  return periodGranularity;
}

export const getGranularityStatus = period => 
{
  let granularity = null;

  if( typeof period === 'string' )
  {
    // get dates from current period
    const dates = getDateFromPeriod( period, null, 'YYYY-MM-DD' );

    // get diff between periods
    const { weekDiff } = getPeriodsDiffFromDate( dates.startDate, dates.endDate );

    // set granularity
    if( weekDiff > 12 )
      granularity = 'month';
    else
      granularity = 'week';
  }

  return granularity;
}

/**
 * Format date from number to string with Moment format
 * @param {Number} date 
 * @param {String} inputFormat 
 * @param {String} outputFormat 
 * @returns {String}
 */
export const formatDate = ( date, inputFormat, outputFormat ) => 
{
  if( 
    inputFormat !== undefined
    && typeof inputFormat === 'string' 
    && outputFormat !== undefined
    && typeof outputFormat === 'string' 
  )
    date = moment( date, inputFormat ).format( outputFormat );

  return date;
}