/** Dependencies */
import { useEffect, useState } from 'react';

/** Components */
import FilterString from './../../Filters/FilterString';

/** Helpers */
import { deepEqual } from './../../../helpers/functions';

function Keywords( props )
{
  const {
    id,
    pictoTitle,
    filterLabel,
    autoCompletedValues,
    initFilterValues,
    callBackFunction
  } = props;

  /** Init State */
  const [ filterValues, setFilterValues ] = useState( initFilterValues !== null ? initFilterValues : null );

  /** Update Range filter values */
  useEffect( () => 
  {
    // callback function if filter values not null
    if( 
      filterValues !== null 
      && typeof callBackFunction === 'function' 
      && !deepEqual( filterValues, initFilterValues )
    )
      callBackFunction( filterValues );

  }, [ filterValues ] )

  return(
    <div className='filter-container keywords'>

      {/* Title */}
      <h4 className='picto-title'>
        { pictoTitle }
        { filterLabel }
      </h4>

      {/* Filter type */}
      <FilterString
        id={ id + '-string' }
        filterLabel={ filterLabel }
        inputLabel={ filterLabel }
        autoCompletedValues={ autoCompletedValues }
        initCurrentValues={ filterValues !== null && filterValues.value ? filterValues.value : null }
        callBackFunction={ values => setFilterValues( { ...filterValues, value: values } ) }
      />

    </div>
  )
}

export default Keywords;