/** Dependencies */
import { ButtonGroup } from 'smart-webcomponents-react/buttongroup';

/** Helpers */
import { getPicto } from './../../helpers/pictos';

function DisplayModeSelector ( props )
{
  const {
    callBackFunction,
    currentValue
  } = props;

  /** Set Datas source for display modes */
  const displayModeDataSource = [
    { label: getPicto( 'ChartBar', { size: '1.5rem' }, true ), value: "full-chart" },
    { label: getPicto( 'Columns', { size: '1.5rem' }, true ), value: "chart-grid" },
    { label: getPicto( 'ListDashes', { size: '1.5rem' }, true ), value: "full-grid" }
  ]

  return(
    <ButtonGroup
      className='display-mode-selector empty'
      dataSource={ displayModeDataSource }
      selectionMode='one'
      selectedValues={ 
        displayModeDataSource.filter( elem => elem.value === currentValue ).length === 1 ?
          [ currentValue ]
          : []
      }
      onChange={ e => callBackFunction( e.detail.values[0] ) }
    />
  )
}

export default DisplayModeSelector;